import * as signalR from "@microsoft/signalr";
import { getServerUrl } from "constants/types";

const alertsHubUrl = getServerUrl() + "/AlertHub";

const createSignalRConnection = (hubUrl = alertsHubUrl) => {
	const connection = new signalR.HubConnectionBuilder()
		.withUrl(hubUrl, {
			accessTokenFactory: () => {
				const token = localStorage.getItem("token"); // Adjust based on your token storage
				return token;
			},
		}) // Replace with your SignalR hub URL
		.withAutomaticReconnect() // Automatically reconnect if the connection is lost
		.configureLogging(signalR.LogLevel.Information) // Optional: Set logging level
		.build();

	return connection;
};

export default createSignalRConnection;
