import React, { useState } from "react";
import { IconButton } from "@mui/material";

import { TextField, Paper, InputAdornment } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import "./ChatAssistant.css";

import voiceAssistantImg from "assets/app/voice-assistant.png";

const ChatAssistant = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  return (
    <div className="chat-container">
      {/* Chat Body */}
      <div className="chat-body">
        {chatMessages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${
              message.sender === "bot"
                ? "chat-message-bot"
                : "chat-message-user"
            }`}
          >
            <Paper className="chat-bubble">{message.text}</Paper>
          </div>
        ))}
      </div>

      {/* Input Section */}
      <div className="chat-input">
        <TextField
          variant="outlined"
          placeholder="Type your message here"
          className="chat-input-field"
          fullWidth
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => {}}>
                  <SendIcon className="chat-send-icon" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton className="chat-voice-button" onClick={() => {}}>
          <img
            src={voiceAssistantImg}
            alt="Voice Assistant"
            className="chat-voice-icon"
          />
        </IconButton>
      </div>
    </div>
  );
};

export default ChatAssistant;
