import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactApexChart from "react-apexcharts";

// const projectTimeline = [
// 	{ activity: "Project Start", startDate: "2024-01-01", endDate: "2024-02-28" },
// 	{ activity: "Excavation", startDate: "2024-02-01", endDate: "2024-03-30" },
// 	{ activity: "Foundation", startDate: "2024-03-01", endDate: "2024-05-30" },
// 	{ activity: "Framing", startDate: "2024-05-01", endDate: "2024-06-30" },
// 	{ activity: "Plumbing", startDate: "2024-06-01", endDate: "2024-07-30" },
// 	{ activity: "Electrical", startDate: "2024-07-01", endDate: "2024-08-30" },
// 	{ activity: "Drywall", startDate: "2024-08-01", endDate: "2024-09-30" },
// 	{ activity: "Painting", startDate: "2024-09-01", endDate: "2024-10-30" },
// 	{ activity: "Finishing", startDate: "2024-10-01", endDate: "2024-11-30" },
// ];

const generateData = (projectTimeline) => {
	return projectTimeline.map((item) => {
		return {
			x: item.activity,
			y: [new Date(item.startDate).getTime(), new Date(item.endDate).getTime()],
		};
	});
};

const TimelineChart = ({ projectTimeline }) => {
	const series = [
		{
			data: generateData(projectTimeline),
		},
	];

	const options = {
		chart: {
			height: 400,
			type: "rangeBar",
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
			animations: {
				enabled: true,
				easing: "easeinout",
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
		},
		plotOptions: {
			bar: {
				horizontal: true,
				borderRadius: 10,
				distributed: true,
				dataLabels: {
					hideOverflowingLabels: false,
				},
				barHeight: "50%", // Reduced from 80% to create vertical gaps
				rangeBarGroupRows: true, // Group bars with gaps
			},
		},
		states: {
			hover: {
				filter: {
					type: "darken",
					value: 0.85,
				},
			},
		},
		dataLabels: {
			enabled: true,
			formatter: function (val, opts) {
				return opts.w.globals.labels[opts.dataPointIndex];
			},
			style: {
				colors: ["#fff"],
				fontSize: "12px",
			},
			offsetX: 5,
		},
		xaxis: {
			type: "datetime",
			labels: {
				format: "MMM",
				style: {
					fontSize: "12px",
				},
			},
			min: new Date("2024-01-01").getTime(),
			max: new Date("2024-12-31").getTime(),
			tickAmount: 12,
			axisBorder: {
				show: true,
				color: "#E5E7EB",
			},
			axisTicks: {
				show: true,
				color: "#E5E7EB",
			},
			tooltip: {
				enabled: false,
			},
		},
		yaxis: {
			show: false,
			labels: {
				style: {
					fontSize: "12px",
				},
			},
		},
		grid: {
			show: true,
			borderColor: "#E5E7EB",
			strokeDashArray: 0,
			position: "back",
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: false,
				},
			},
			padding: {
				top: 10,
				right: 20,
				bottom: 10,
				left: 10,
			},
		},
		colors: ["#345DAE"],
		legend: {
			show: false,
		},
		tooltip: {
			custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				const data = w.config.series[seriesIndex].data[dataPointIndex];
				const startDate = new Date(data.y[0]).toLocaleDateString("en-US", {
					month: "short",
					year: "numeric",
				});
				const endDate = new Date(data.y[1]).toLocaleDateString("en-US", {
					month: "short",
					year: "numeric",
				});
				return `
          <div class="p-2">
            <div class="font-bold">${data.x}</div>
            <div class="text-sm">${startDate} - ${endDate}</div>
          </div>
        `;
			},
		},
		responsive: [
			{
				breakpoint: 768,
				options: {
					plotOptions: {
						bar: {
							barHeight: "40%", // Smaller height on mobile
						},
					},
					xaxis: {
						labels: {
							style: {
								fontSize: "10px",
							},
						},
					},
				},
			},
		],
	};

	return (
		<div className="w-full">
			<div style={{ height: "400px" }}>
				<ReactApexChart
					options={options}
					series={series}
					type="rangeBar"
					height="100%"
					width="100%"
				/>
			</div>
		</div>
	);
};

export default TimelineChart;
