import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.job;

export const getJobsList = createSelector(selectState, (jobs) => ({
	jobsList: get(jobs, "jobList", []),
	totalCount: get(jobs, "totalCount", 0),
}));

export const getjobData = createSelector(selectState, (job) =>
	get(job, "jobData", [])
);

export const getJobUserRolesList = createSelector(selectState, (user) => ({
	jobUsersRolesList: get(user, "jobUsersList", []),
	jobUsersRolesLoading: get(user, "jobUserLoading", false),
}));

export const getJobDynamicOptions = createSelector(selectState, (job) => {
	return {
		jobStatus: get(job, "jobStatus", []).map((status) => status.statusName),
		jobStatusId: get(job, "jobStatus", []).map((option) => ({
			key: option?.jobStatusId,
			text: option?.statusName,
		})),
		jobType: get(job, "jobType", []).map((type) => type.typeName),
		jobTypeId: get(job, "jobType", []).map((option) => ({
			key: option?.jobTypeId,
			text: option?.jobType,
		})),
	};
});
