import React from "react";
import "./WavesListeningAnimation.css";
import { useVoiceAssistantState } from "context/VoiceAssistantContext";

const WavesListeningAnimation = () => {
  const { VoiceAssistantState, setVoiceAssistantState } =
    useVoiceAssistantState();

  return (
    <div className="wave-container"
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "200px",
        zIndex: 1500, // Higher than the FAB
        pointerEvents: "none", // Prevent interactions with the waves
      }}
    >
      <svg className="wave wave1" viewBox="0 0 1200 100" preserveAspectRatio="none">
        <path d="M0,50 C300,150 900,-50 1200,50"></path>
      </svg>
      <svg className="wave wave2" viewBox="0 0 1200 100" preserveAspectRatio="none">
        <path d="M0,50 C300,150 900,-50 1200,50"></path>
      </svg>
    </div>
  
  );
};

export default WavesListeningAnimation;
