import {
	TASK_LOADING,
	TASK_GET_ALL_SUCCESS,
	TASK_ERROR,
	TASK_CLEAR_SNACKBAR,
} from "../constants/types";
import { ActionConstants } from "constants";

const initialState = {
	taskList: [],
	loading: true,
	status: "success",
	text: "",
	showSnackbar: false,
};

const taskReducer = (state = initialState, action) => {
	switch (action.type) {
		case TASK_CLEAR_SNACKBAR:
			return {
				...state,
				showSnackbar: false,
			};
		case TASK_LOADING:
			return {
				...state,
				loading: true,
			};
		case TASK_ERROR:
			return {
				...state,
				taskList: [],
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case TASK_GET_ALL_SUCCESS:
			return {
				...state,
				taskList: action.payload.taskList,
				totalCount: action.payload.totalCount,
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case ActionConstants.GET_TASK_BY_ID_SUCCESS:
			return {
				...state,
				taskData: action.payload.response,
			};
		case ActionConstants.GET_TASK_BY_ID_ERROR:
			return {
				...state,
			};
		case ActionConstants.GET_TASK_STATUS_SUCCESS:
			return {
				...state,
				taskStatus: action.payload.response,
			};
		default:
			return state || {};
	}
};

export default taskReducer;
