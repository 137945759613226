import React, { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddNewProperty from "./AddNewProperty";
import { getPropertyById } from "../../actions/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyData } from "selectors/propertySelectors";

export const EditProperty = () => {
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const propertyData = useSelector(getPropertyData);

	const getPropertyDetails = useCallback(() => {
		dispatch(getPropertyById(params.propertyId));
	}, [dispatch, params.propertyId]);

	useEffect(() => {
		getPropertyDetails();
	}, [getPropertyDetails]);

	return propertyData && propertyData.propertyId ? (
		<>
			<AddNewProperty
				propertyId={params.propertyId}
				selectedProperty={{ data: propertyData }}
				title={" "}
				onCancel={() => {
					navigate(`/properties`);
				}}
			/>
		</>
	) : null;
};

export default EditProperty;
