import React, { useEffect, useState } from "react";
import { Step, StepLabel, Stepper, TextField } from "@mui/material";
import { getText } from "core/utils/getContentText";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
	getGuidDetailsAction,
	registerUserDetailsAction,
} from "actions/login_registrationActions";
import { green } from "@mui/material/colors";
import { getFormFieldsByUser } from "./registrationUtils";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { palette } from "themes";
import { Check, Error } from "@mui/icons-material";
import PaymentInformation from "./PaymentInformation";
import get from "lodash/get";
import { getApiError, getEmailFromGuid } from "selectors/appSelector";
// import { getGuidDetails } from "selectors/userSelector";
import { fetchInviteDetailsByGuid } from "actions/userActions";
import { GUID_ERROR_MESSAGE } from "constants/appConstants";

export const UserRegistrationForm = ({ userType, setShowConfirmationPage }) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const urlParams = queryString.parse(location.search);

	// const { guidDetails } = useSelector(getGuidDetails);
	const { email, guidError } = useSelector(getEmailFromGuid);

	useEffect(() => {
		dispatch(fetchInviteDetailsByGuid({ InvGuid: urlParams.GUID }));
		dispatch(getGuidDetailsAction(urlParams.GUID));
	}, [urlParams.GUID, dispatch]);

	const [activeStep, setActiveStep] = useState(0);
	const { error } = useSelector(getApiError);

	const { steps, formFields, validationSchema, mandatoryFieldsByStep } =
		getFormFieldsByUser(userType);
	const initialState = getFormFieldsByUser(userType).defaultState({
		email,
	});

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			const currentStepFields = formFields[`step${activeStep}`].map(
				(field) => field.key
			);
			if (activeStep === 0) {
				const extractedFields = omit(pick(formik.values, currentStepFields), [
					"confirmPassword",
				]);
				dispatch(
					registerUserDetailsAction(
						{ ...extractedFields, userType },
						userType,
						() => {
							setActiveStep(activeStep + 1);
						}
					)
				);
			}
		},
	});

	useEffect(() => {
		formik.setValues(initialState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email]);

	const shouldDisable = () => {
		const shouldDisableButton = mandatoryFieldsByStep[activeStep].some(
			(field) => {
				return Object.keys(formik.errors).includes(field);
			}
		);
		if (mandatoryFieldsByStep[0].includes("password")) {
			return (
				shouldDisableButton ||
				!formik.values.password ||
				!formik.values.confirmPassword ||
				formik.values.password !== formik.values.confirmPassword
			);
		}
		return shouldDisableButton;
	};

	function CustomStepIcon(props) {
		const { completed, index } = props;
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: 40,
					height: 40,
					borderRadius: "10%",
					background: completed
						? "#36B37E"
						: index === activeStep
						? palette.bluePrimary
						: "lightgray",
					color: "white",
					border: "none",
					cursor: "pointer",
				}}
			>
				{completed ? <Check /> : index + 1}
			</div>
		);
	}

	return email && !guidError ? (
		<div className="registerForm-container">
			<Stepper
				alternativeLabel
				activeStep={activeStep}
				className="stepperHeader"
			>
				{steps.length > 1 &&
					steps.map((label, index) => (
						<Step key={label}>
							<StepLabel
								StepIconComponent={(props) => (
									<CustomStepIcon {...props} index={index} />
								)}
								StepIconProps={{ color: green[500] }}
							>
								<span style={{ fontWeight: 700 }}>{label}</span>
							</StepLabel>
						</Step>
					))}
			</Stepper>
			{activeStep === 1 ? (
				<div className="stepContent container row">
					<PaymentInformation
						setShowConfirmationPage={setShowConfirmationPage}
					/>
				</div>
			) : (
				<form onSubmit={formik.handleSubmit}>
					<div className="stepContent container row">
						{formFields[`step${activeStep}`].map((field, index) => {
							const extraInputProps = get(field, "extraInputProps", {});

							const extraProps =
								typeof extraInputProps === "function"
									? extraInputProps(formik)
									: extraInputProps;
							return (
								<div
									className="col-6 col-xs-12 formField"
									style={{ width: field.width ? field.width : "50%" }}
								>
									<p>
										<label className="form-label">
											{getText(`registrationForm.${userType}.${field.label}`)}
											{field.required && (
												<sup style={{ color: "red", fontSize: "1em", top: 0 }}>
													*
												</sup>
											)}
										</label>
									</p>
									<TextField
										key={field.key}
										id={field.key}
										type={field.type}
										name={field.key}
										placeholder={field.placeholder}
										fullWidth
										variant="outlined"
										className={` registration-formField ${field.key}`}
										value={formik.values[field.key]}
										disabled={field.disabled}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched[field.key] &&
											Boolean(formik.errors[field.key])
										}
										helperText={
											formik.touched[field.key] && formik.errors[field.key]
										}
										{...extraProps}
									/>
								</div>
							);
						})}
						<p
							style={{
								margin: "1rem",
								color: "red",
								fontSize: "12px",
								textAlign: "center",
							}}
						>
							{formik.values.password &&
							formik.values.confirmPassword &&
							formik.values.password !== formik.values.confirmPassword
								? "Password doesn't match with confirm password"
								: ""}
						</p>
						{error && error !== "" && (
							<p
								className="error text-center"
								style={{ color: "red", fontSize: "12px" }}
							>
								{error}
							</p>
						)}

						<div style={{ textAlign: "right" }}>
							<div className="d-flex justify-content-between">
								<div></div>
								<div className="float-right">
									<button
										type="submit"
										className={`save-details-button m-4 primaryButton ${
											shouldDisable() ? "disabledButton" : ""
										}`}
										disabled={shouldDisable() ? "disabledButton" : ""}
									>
										{activeStep === 1 ? "Submit" : "Next >"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
		</div>
	) : guidError ? (
		<div className="h2 text-center ">
			<div>
				<Error style={{ fontSize: "3rem", color: "red" }} />
			</div>
			{guidError || GUID_ERROR_MESSAGE}
		</div>
	) : (
		""
	);
};

export default UserRegistrationForm;
