import React from "react";
import FileViewer from "react-file-viewer";

export const DocumentPreview = ({ url, file }) => {
	// const msViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
	// 	url
	// )}`;
	const onError = (e) => {
		console.error("Error while previewing document:", e);
	};

	return (
		<>
			<div style={{ height: "100%" }}>
				<FileViewer fileType={file.type} filePath={url} onError={onError} />
			</div>
		</>
	);
};

export default DocumentPreview;
