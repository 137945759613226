import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import get from "lodash/get";

const BreadCrumb = ({ className }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname, state = {} } = location;

	function handleClick(routeTo) {
		navigate(routeTo, { state });
	}
	const pathnames = pathname
		.split("/")
		.filter((x) => x)
		.map((value, index, arr) => {
			return {
				value: decodeURI(value),
				routeTo: `/${arr.slice(0, index + 1).join("/")}`,
			};
		})
		.filter(({ value }) => value);

	// const routeNames = { ...state, ...ROUTE_NAMES };

	const routeNames = {
		projects: "Projects",
	};

	return (
		<Breadcrumbs
			// separator={<NavigateNextIcon fontSize="small" />}
			aria-label="breadcrumb"
			className={`bread-crumbs ${className}`}
		>
			{pathnames.length > 0 ? (
				<Link
					to="/"
					className="bread-crumbs-link"
					onClick={() => handleClick("/")}
				>
					Home
				</Link>
			) : (
				<Typography className="bread-crumbs-text"> Home </Typography>
			)}
			{pathnames.map(({ value, routeTo }, index) => {
				const isLast = index === pathnames.length - 1;
				const name = value in routeNames ? routeNames[value] : value;
				return isLast ? (
					<Typography className="bread-crumbs-text" key={name}>
						{name}
					</Typography>
				) : (
					<Link
						className="bread-crumbs-link"
						underline="hover"
						color="inherit"
						key={name}
						onClick={() => handleClick(routeTo)}
					>
						{name}
					</Link>
				);
			})}
		</Breadcrumbs>
	);
};

export const DynamicBreadCrumb = () => {
	const location = useLocation();
	const { pathname, state = {} } = location;
	const pathSegments = pathname.split("/").filter(Boolean);

	console.log("Check the Location State: ", state, location);

	const breadcrumbNameMap = {
		properties: "Properties",
		projects: "Projects",
		jobs: "Jobs",
		tasks: "Tasks",
	};

	const getBreadcrumbName = (segment, index) => {
		if (breadcrumbNameMap[segment]) return breadcrumbNameMap[segment];
		if (segment.match(/^\d+$/)) {
			const prevSegment = pathSegments[index - 1];
			return `${breadcrumbNameMap[prevSegment] || prevSegment} ${segment}`;
		}
		return segment.charAt(0).toUpperCase() + segment.slice(1);
	};

	return (
		<nav className="flex items-center space-x-2 p-0 mt-1 ms-2 bg-gray-100 rounded-md">
			<Link
				to="/"
				className="pointer me-1 ms-1 flex items-center text-blue-600 hover:underline"
			>
				Home
			</Link>
			{pathSegments.map((segment, index) => {
				const to = `/${pathSegments.slice(0, index + 1).join("/")}`;
				const isLast = index === pathSegments.length - 1;
				const displayName = getBreadcrumbName(segment, index);

				return (
					<React.Fragment key={to}>
						<span className="text-gray-500">/</span>
						{isLast ? (
							<span className=" me-1 ms-1" style={{ color: "#8B8B8B" }}>
								{displayName}
							</span>
						) : (
							<Link
								to={to}
								className="me-1 ms-1 text-blue-600 hover:underline pointer"
							>
								{displayName}
							</Link>
						)}
					</React.Fragment>
				);
			})}
		</nav>
	);
};

const items = ["properties", "propertyName", "projects", "projectName"];

export const CustomBreadCrumb = ({ breadCrumbItems = items }) => {
	const location = useLocation();
	const state = get(location, "state", {}) || {};

	const pathMappings = {
		properties: { path: "/properties", text: "Properties" },
		propertyName: {
			path: `/properties/${state.propertyId}`,
			text: state.propertyName,
		},
		projects: {
			path: `/properties/${state.propertyId}`,
			text: state.projectName,
		},
		projectName: {
			path: `/properties/${state.propertyId}`,
			text: state.projectName,
		},
		alerts: {
			path: "/alerts",
			text: "Alerts",
		},
	};
	return (
		<nav className="">
			<Link
				to="/"
				className="pointer me-1 ms-1 flex items-center "
				style={{
					fontWeight: "400",
					fontSize: "14px",
					color: "#345DAE",
					textDecoration: "initial",
					lineHeight: "21px",
				}}
			>
				Home
			</Link>
			{breadCrumbItems.map((crumbItem, index) => {
				const segment = pathMappings[crumbItem];

				const to = segment.path;
				const isLast = index === breadCrumbItems.length - 1;
				const displayName = segment.text;

				return (
					<React.Fragment key={to}>
						<span style={{ color: "#8B8B8B" }}>\</span>
						{isLast ? (
							<span
								className=" me-1 ms-1"
								style={{
									fontWeight: "400",
									fontSize: "14px",
									color: "#8B8B8B",
									textDecoration: "initial",
									lineHeight: "21px",
								}}
							>
								{displayName}
							</span>
						) : (
							<Link
								to={to}
								className="me-1 ms-1 pointer"
								style={{
									fontWeight: "400",
									fontSize: "14px",
									color: "#345DAE",
									textDecoration: "initial",
								}}
							>
								{displayName}
							</Link>
						)}
					</React.Fragment>
				);
			})}
		</nav>
	);
};

export default BreadCrumb;
