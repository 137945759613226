import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.task;

export const getTasks = createSelector(selectState, (task) => ({
	tasksList: get(task, "taskList", []),
	totalCount: get(task, "totalCount", 0),
}));

export const getTaskData = createSelector(selectState, (task) =>
	get(task, "taskData", [])
);

export const getTaskdynamicOptions = createSelector(selectState, (task) => {
	return {
		taskStatus: get(task, "taskStatus", []).map((status) => status.statusName),
		taskStatusId: get(task, "taskStatus", []).map((option) => ({
			key: option?.taskStatusId,
			text: option?.statusName,
		})),
	};
});
