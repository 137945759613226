import React, { useEffect, useRef, useState } from "react";
import { useSpeechRecognition } from "react-speech-recognition";
import SpeechRecognition from "react-speech-recognition";

import {
  Box,
  IconButton,
  Avatar,
  Typography,
  Button,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

import { TextField, Paper, InputAdornment } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import "./ChatAssistant.css";

import voiceAssistantImg from "assets/app/voice-assistant.png";

import { useVoiceAssistantState } from "context/VoiceAssistantContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MOBILE_MAX_WIDTH_768,
  voiceAssistantPath,
} from "constants/appConstants";

let chatMessagesHistory = [
  { sender: "bot", text: "How may I be of help to you?" },
];

const showArrowBack = false;

const VoiceAssistantLayout = () => {
  const { VoiceAssistantState, setVoiceAssistantState } =
    useVoiceAssistantState();

  const MAX_AWAIT_TIME_ELAPSED_SECONDS = 5;
  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH_768);

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  console.log("VoiceAssistantLayout");

  const handleInputSend = () => {
    if (inputMessage.trim()) {
      console.log("Message sent:", inputMessage);
      handleSend("user", inputMessage);

      setInputMessage("");
    }
  };

  const handleSend = (sender, text) => {
    if (text.trim()) {
      chatMessagesHistory.push({ sender, text });

      console.log("chatMessagesHistory:", chatMessagesHistory);
      setChatMessages([...chatMessagesHistory]);
    }
  };

  const resetMessagesHistory = (sender, text) => {
    chatMessagesHistory = [
      { sender: "bot", text: "How may I be of help to you?" },
    ];

    setChatMessages([...chatMessagesHistory]);
  };

  const [message, setMessage] = useState("");

  const commands = [
    {
      command: "reset",
      callback: () => {
        setMessage(null);
        resetTranscript();

        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });
      },
    },
    {
      command: "shut up",
      callback: () => setMessage("I wasn't talking."),
    },
    {
      command: "Stop",
      callback: () => {
        setMessage(null);
        resetTranscript();

        setVoiceAssistantState({
          ...VoiceAssistantState,
          isListening: false,
        });
      },
    },
    {
      command: "Hello",
      callback: () => setMessage("Hi there!"),
    },
    {
      command: "Create Contact",
      callback: () => {
        handleSend("bot", "Command received: Create Contact");
        setMessage("Creating New Customer");
      },
    },
    {
      command: "Create job",
      callback: () => {
        handleSend("bot", "Command received: Create job");
      },
    },
    {
      command: "Create project",
      callback: () => {
        handleSend("bot", "Command received: Create project");
      },
    },
    {
      command: "Create property",
      callback: () => {
        handleSend("bot", "Command received: Create property");
      },
    },
    {
      command: "check if customer is present in the system",
      callback: () => {
        setMessage("Checking in the system");
        speak("Checking in the system");
      },
    },
  ];

  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [typewriterText, setTypewriterText] = useState("");

  const [lastCalled, setLastCalled] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const intervalRef = useRef(null);

  const {
    finalTranscript,
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ commands });

  const handleVoiceCommand = (startListening) => {
    if (startListening) {
      SpeechRecognition.startListening({
        language: "en-US",
        continuous: true,
      });
    } else {
      SpeechRecognition.stopListening();
    }
  };

  useEffect(() => {
    let currentIndex = 0;
    setTypewriterText("");

    const typewriterEffect = () => {
      console.log("typewriterEffect", finalTranscript);

      if (currentIndex < finalTranscript.length) {
        setTypewriterText((prev) => {
          setLastCalled(Date.now());

          if (
            typeof prev !== "undefined" &&
            typeof finalTranscript[currentIndex] !== "undefined"
          ) {
            return prev + finalTranscript[currentIndex];
          }

          if (typeof prev !== "undefined") {
            return prev;
          }

          if (typeof finalTranscript[currentIndex] !== "undefined") {
            return finalTranscript[currentIndex];
          }
        });

        currentIndex++;
        setTimeout(typewriterEffect, 70);
      }
    };

    typewriterEffect();
  }, [finalTranscript]);

  useEffect(() => {
    if (lastCalled !== null) {
      // Clear any existing interval
      if (intervalRef.current) clearInterval(intervalRef.current);

      // Start a new interval to update elapsed time
      intervalRef.current = setInterval(() => {
        const timeElapsed = Math.floor((Date.now() - lastCalled) / 1000);

        setTimeElapsed(timeElapsed);

        if (timeElapsed >= MAX_AWAIT_TIME_ELAPSED_SECONDS) {
          setVoiceAssistantState({
            ...VoiceAssistantState,
            isListening: false,
          });

          handleSend("user", finalTranscript);
          setTypewriterText("");
          resetTranscript();

          setTimeout(() => {
            // handleSend("bot", "Provide more details");
            clearInterval(intervalRef.current);
          }, 500);
        }
      }, 1000);

      // Cleanup interval on unmount
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [lastCalled]);

  useEffect(() => {
    console.log("VoiceAssistantState:", VoiceAssistantState);
    handleVoiceCommand(VoiceAssistantState.isListening);
  }, [VoiceAssistantState.isListening]);

  useEffect(() => {
    if (!isMobile && pathname.includes(voiceAssistantPath)) {
      navigate(-1);
    }
  }, [isMobile, navigate, pathname]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const speak = (text) => {
    var msg = new SpeechSynthesisUtterance();
    msg.text = text;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(msg);
  };

  return (
    <div>
      {showArrowBack && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: 2,
          }}
        >
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      {/* Main Content */}
      {chatMessages?.length === 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {/* Profile Avatar */}
          <Avatar
            src={voiceAssistantImg}
            alt="Helper"
            sx={{ width: 80, height: 80, mb: 2 }}
          />
          <Typography variant="h6" fontWeight="bold">
            How may I be of help to you?
          </Typography>

          {
            <>
              <Typography variant="subtitle2" sx={{ mt: 2, color: "gray" }}>
                QUICK SUGGESTIONS
              </Typography>

              {/* Quick Suggestion Buttons */}
              <Stack spacing={2} sx={{ mt: 2, width: "100%", maxWidth: 300 }}>
                <Button variant="outlined" fullWidth>
                  Send an estimate
                </Button>
                <Button variant="outlined" fullWidth>
                  Search for a property
                </Button>
                <Button variant="outlined" fullWidth>
                  Create a New Build project
                </Button>
              </Stack>
            </>
          }
        </Box>
      )}

      {chatMessages?.length > 0 && (
        <>
          <div className="chat-container">
            {/* Chat Body */}
            <div className="chat-body">
              {chatMessages.map((message, index) => (
                <div
                  key={index}
                  className={`chat-message ${
                    message.sender === "bot"
                      ? "chat-message-bot"
                      : "chat-message-user"
                  }`}
                >
                  <Paper className="chat-bubble">{message.text}</Paper>
                </div>
              ))}
            </div>

            {/* Input Section */}
            {/* <div className="chat-input">
              <TextField
                variant="outlined"
                placeholder="Type your message here"
                className="chat-input-field"
                fullWidth
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleInputSend}>
                        <SendIcon className="chat-send-icon" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                className="chat-voice-button"
                onClick={handleVoiceCommand}
              >
                <img
                  src={voiceAssistantImg}
                  alt="Voice Assistant"
                  className="chat-voice-icon"
                />
              </IconButton>
            </div> */}
          </div>
        </>
      )}

      {chatMessages?.length === 0 && listening && (
        <>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {timeElapsed}: {typewriterText}
          </Typography>
        </>
      )}

      {/* Listening Icon at the Bottom */}
      {/* {!listening && chatMessages?.length === 0 && ( */}
      {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 4,
          }}
        > */}

      {/* <Typography variant="caption" sx={{ color: 'gray' }}>
            {listening ? displayedText : 'Click to start listening'}
          </Typography>

          {listening && (
            <>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {typewriterText}
              </Typography>
            </>
          )}
        </Box> */}
      {/* )} */}

      {/* {chatMessages?.length === 0 && listening && message === '' && (
      )} */}
    </div>
  );
};

export default VoiceAssistantLayout;
