import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/appConstants";

const getTextContent = (key) => getText(`projects.addProject.${key}`);

const addProjectValidationSchema = yup.object({
	propertyId: yup
		.string("Select Property For Project")
		.required("Property is required"),
	interiorArea: yup
		.number("Enter Plot Area (Sq.Ft.)")
		.typeError("Please enter a valid number")
		.integer("Interior area must be an valid value")
		.nullable(),
	projectEstimatedCost: yup
		.number("Enter Esitmated Cost")
		.required("Project Estimated cost is required")
		.typeError("Please enter a valid number")
		.integer("budget must be a valid number"),
	projectStatusId: yup.string("Select Stage").required("Stage is required"),
	projectType: yup
		.string("Type of Project")
		.required("Project type is required"),
	plannedStartDate: yup
		.string("Enter Planned Start Date")
		.required("Planned Start Date is required"),
	plannedEndDate: yup
		.string("Enter Planned End Date")
		.required("Planned End Date is required"),
});

export const addProjectFields = {
	propertyInformation: [
		{
			key: "projectName",
			label: getTextContent("name"),
			placeholder: "Project Name",
			type: "text",
			width: "50%",
		},
		{
			key: "projectSupervisor",
			label: "Project Supervisor",
			placeholder: "Select From List",
			type: "dynamicField",
			value: "",
			select: true,
			options: [""],
			width: "50%",
		},
		{
			key: "interiorArea",
			label: "Interior Area (Sq.Ft)",
			placeholder: "Enter Construction Area",
			type: "text",
			width: "50%",
		},
		{
			key: "projectEstimatedCost",
			label: "Project Estimated Cost",
			placeholder: "Enter Estimated Project cost in USD",
			type: "text",
			required: true,
			width: "50%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "projectStatusId",
			label: getTextContent("stage"),
			placeholder: "Select Stage",
			type: "select",
			value: "Contracted",
			select: true,
			options: [],
			required: true,
			width: "50%",
		},

		{
			key: "projectType",
			label: "Project Type",
			placeholder: "Type of Construction",
			type: "select",
			select: true,
			options: ["New Build", "Renovation", "Remodel", "Tear Down Rebuild"],
			required: true,
			width: "50%",
		},
		{
			key: "plannedStartDate",
			placeholder: DATE_FORMAT,
			label: "Planned Start Date",
			type: "dateField",
			required: true,
			width: "50%",
		},
		{
			key: "plannedEndDate",
			placeholder: DATE_FORMAT,
			label: "Planned End Date",
			type: "dateField",
			width: "50%",
			required: true,
			extraInputProps: (formValues) => ({
				disablePast: true,
				disabled: !formValues.values["plannedStartDate"],
				minDate: dayjs(formValues.values["plannedStartDate"]).add(0, "day"),
				openTo: "day",
			}),
		},
		{
			key: "projectNotes",
			label: "Project Notes",
			placeholder: "Enter Project notes here",
			type: "textArea",
			width: "100%",
			extraInputProps: {
				multiline: true,
				className:
					"registration-formField project-notes property-details-textarea",
			},
		},
		{
			key: "warrantyStartDate",
			placeholder: "MM/DD/YYYY",
			label: "Warranty Start Date",
			type: "dateField",
			width: "50%",
		},
		{
			key: "warrantyEndDate",
			placeholder: "MM/DD/YYYY",
			label: "Warranty End Date",
			type: "dateField",
			width: "50%",
			extraInputProps: (formValues) => ({
				disablePast: true,
				disabled: !formValues.values["warrantyStartDate"],
				minDate: dayjs(formValues.values["warrantyStartDate"]).add(0, "day"),
				openTo: "day",
			}),
		},
	],
};

export const addProjectForm = {
	validationSchema: addProjectValidationSchema,
	defaultState: (defaultState) => {
		const initialState = Object.fromEntries(
			Object.keys(addProjectValidationSchema.fields).map((key) => {
				return [key, ""];
			})
		);
		if (defaultState) {
			return { ...initialState, ...defaultState };
		}
		return {
			...initialState,
		};
	},
};
