import React, { useState, useEffect, useContext } from "react";
import AddEditJob from "./AddNewJob";
import { useDispatch, useSelector } from "react-redux";
import { jobDelete, jobGetAll } from "actions/jobActions";
import { getJobsList } from "selectors/jobSelectors";
import { IconButton, Typography } from "@mui/material";
import DataGridTable from "components/Table/DataGrid";

import { useNavigate } from "react-router-dom";
import AddEditTask from "pages/task/AddNewTask";
import get from "lodash/get";
import { jobListcolumns } from "./addJobUtils";
import NoResultsFound from "components/NoResults/NoResultsFound";
import AppRoleContext from "context/AppRoleContext";
import HiddenOn from "components/Wrappers/HiddenOn";
import { hasAppRole, roleConfigKeys } from "core/utils/roleUtils";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import JobTeamList from "pages/projects/tabComponents/jobs/JobTeamList";

export const Jobs = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { jobsList, totalCount } = useSelector(getJobsList);

	const [addNewJob, setAddNewJob] = useState(false);

	const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [editJob, setEditJob] = useState(false);
	const [addTask, setAddTask] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);
	const [userManagement, setUserManagement] = useState(false);
	const [jobActionsContext, setJobActionsContext] = useState(null);

	const appRoleDetails = useContext(AppRoleContext);

	useEffect(() => {
		dispatch(jobGetAll({ ...pageModel }));
	}, [dispatch, pageModel]);

	if (addTask) {
		return (
			<AddEditTask
				onCancel={() => setAddTask(false)}
				parentId={{ jobId: selectedJob.id }}
			/>
		);
	}

	return (
		<div className="jobs-page">
			<ModelDialog
				fullWidth
				open={userManagement}
				handleClose={() => {
					setUserManagement(false);
				}}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography className="h4 mb-4">{"Assign Job"}</Typography>
						<IconButton
							onClick={() => {
								setUserManagement(false);
							}}
						>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					<>
						{/* <ManageJobUsers
							jobRefId={jobActionsContext}
							projectId={projectId}
						/> */}
						<JobTeamList projectRefId={jobActionsContext} title={" "} />
					</>
				}
			/>
			{addNewJob ? (
				<AddEditJob
					onCancel={() => setAddNewJob(false)}
					jobId={editJob}
					selectedJob={selectedJob}
					title={get(selectedJob, "data.projectName", null)}
				/>
			) : (
				<>
					<div className="projects-header-section">
						<div>
							<Typography className="h1">Jobs</Typography>
							{/* <BreadCrumb /> */}
						</div>
					</div>
					<div className="projects-content-section">
						{totalCount > 0 ? (
							<DataGridTable
								pagination={totalCount > 10}
								totalCount={totalCount}
								columns={jobListcolumns({
									setEditJob,
									setAddTask,
									setAddNewJob,
									setSelectedJob,
									appRoleDetails,
									onJobDelete: (jobId, job) => {
										dispatch(
											jobDelete({
												jobId,
												projectId: job.projectId,
												params: pageModel,
											})
										);
									},
									setUserManagement,
									setJobActionsContext,
								})}
								data={jobsList}
								defineRowId={(row) => {
									return row.jobId;
								}}
								paginationModel={pageModel}
								onPaginationChange={(props) => {
									setPageModel(props);
								}}
								onSelect={(props) => {
									setSelectedRows(props);
								}}
								selectedRows={selectedRows}
								onDeleteSelection={() => {
									console.log("Delete Selection : ", selectedRows);
								}}
								onRowClick={(row) => {
									navigate(`/jobs/view/${row.id}`);
								}}
							/>
						) : (
							<NoResultsFound />
						)}
					</div>
				</>
			)}

			<HiddenOn
				isHidden={!hasAppRole(roleConfigKeys.CREATE_JOB, appRoleDetails)}
			>
				{!addNewJob && (
					<div className="page-footer  fixed-footer align-content-center">
						<div className="d-flex justify-content-end ">
							<button
								className="primaryButton addProjectButton"
								style={{ width: "8rem" }}
								onClick={() => {
									setAddNewJob(!addNewJob);
								}}
							>
								{"+ Add Job"}
							</button>
						</div>
					</div>
				)}
			</HiddenOn>
		</div>
	);
};

export default Jobs;
