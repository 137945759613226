import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Typography, IconButton } from "@mui/material";
import { addTaskForm, addTaskFields } from "./addTaskUtils";
import get from "lodash/get";
import { CloseOutlined } from "@mui/icons-material";
import "./tasks.scss";
import FormInputField, {
	FormLabel,
} from "components/TextFields/FormInputField";
import DatePickerField from "components/DateComponets/DatePicker";
import { addNewTask, fetchTaskStatus } from "actions/taskActions";
import { getTaskdynamicOptions } from "selectors/taskSelectors";
import dayjs from "dayjs";

export const AddNewTask = ({
	onCancel,
	taskId,
	jobId,
	selectedTask = {},
	title,
	parentIds,
	projectId,
}) => {
	const dispatch = useDispatch();

	const { validationSchema } = addTaskForm;
	const initialState = addTaskForm.defaultState(
		get(selectedTask, "data", null)
	);

	const dynamicOptions = useSelector(getTaskdynamicOptions);

	console.log("Check the Project Id	", projectId);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			dispatch(addNewTask({ ...formik.values, jobId, projectId }, onCancel));
		},
	});

	React.useEffect(() => {
		dispatch(fetchTaskStatus());
	}, [dispatch]);

	return (
		<div className="mt-0 p-1">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h3" id="header">
					{title || "Create New Task"}
				</Typography>
				<IconButton onClick={() => onCancel()}>
					<CloseOutlined />
				</IconButton>
			</div>
			<div id="create-task">
				<form onSubmit={formik.handleSubmit}>
					<div id="container row">
						<div id="section-1 mt-3">
							<div className="d-flex">
								<div className="form-fields col-12">
									<div className="container row justify-content-between">
										{addTaskFields.map((field) => {
											const extraProps = get(field, "extraInputProps", {});

											if (field.type === "dateField") {
												return (
													<div
														className={`form-field-wrapper ${field.className}`}
														style={{ width: field.width }}
													>
														<FormLabel
															text={field.label}
															required={field.required}
														/>
														<DatePickerField
															onChange={(date) => {
																if (
																	!date ||
																	dayjs(date).isValid() === false ||
																	dayjs(date).year() === 1970
																) {
																	formik.setFieldValue(field.key, null); // Explicitly set to null
																} else {
																	formik.setFieldValue(field.key, date);
																}
															}}
															onClear={() => {
																formik.setFieldValue(field.key, null);
															}}
															value={formik.values[field.key] || null}
															field={field}
															slotProps={{
																field: {
																	clearable: !field.required,
																	onClear: () => {
																		formik.setFieldValue(field.key, null);
																	},
																},
															}}
															{...extraProps}
														/>
													</div>
												);
											}

											if (field.type === "dynamicField") {
												return <></>;
											}

											return (
												<FormInputField
													field={{
														...field,
														options: dynamicOptions[field.key] || field.options,
													}}
													formik={formik}
													value={formik.values[field.key]}
													key={field.key}
													{...extraProps}
												/>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="mt-5">
							<div className="d-flex justify-content-center align-self-center">
								<button
									type="button"
									className={`secondaryButton m-3`}
									onClick={() => {
										onCancel();
									}}
								>
									{"Cancel"}
								</button>
								<button type="submit" className={`primaryButton m-3`}>
									{console.log("Check the Task Id : ", taskId)}
									{taskId ? "Update Task" : "Create Task"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddNewTask;
