import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
	TextField,
	MenuItem,
	Box,
	Chip,
	Typography,
	IconButton,
	FormHelperText,
	Grid,
	LinearProgress,
} from "@mui/material";

import { addPropertyForm } from "./addPropertyUtils";
import get from "lodash/get";
import { LocationField } from "components/Location/GeoLocation";
import { addNewProperty } from "actions/propertyActions";
import { FormLabel } from "components/TextFields/FormInputField";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getUserProfileDetails } from "selectors/appSelector";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { fetchFiles } from "actions/documentsActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";
import "./properties.scss";
import { useNavigate } from "react-router-dom";

export const AddNewProperty = ({
	onCancel = () => {
		window.history.back();
	},
	propertyId,
	selectedProperty,
	title,
	callback = () => {},
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [activeStep] = useState(0);

	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});

	const existingFiles = useSelector(selectAllFiles);

	const isUploading = useSelector(selectIsUploading);

	const appRoleDetails = useSelector(getUserProfileDetails);

	const { formFields, validationSchema } = addPropertyForm;

	const initialState = addPropertyForm.defaultState(
		get(selectedProperty, "data", null)
	);

	const fetchExistingDocs = useCallback(() => {
		if (propertyId) {
			dispatch(fetchFiles("Property", propertyId));
		}
	}, [dispatch, propertyId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			dispatch(
				addNewProperty(
					{ ...formik.values, companyId: appRoleDetails.companyId },
					{ filesState, fileLabelsState },
					(payload) => {
						callback(payload);
						// onCancel();
					},
					navigate
				)
			);
		},
		initialTouched: get(selectedProperty, "data", null),
	});

	return (
		<div className="mt-2 p-2">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h1" id="header">
					{title || "Create New Property"}
				</Typography>
				<IconButton onClick={() => onCancel()}>
					<CloseOutlined />
				</IconButton>
			</div>
			<div className="form-section">
				<div>
					<form onSubmit={formik.handleSubmit}>
						<div className="stepContent container row">
							{formFields[`step${activeStep}`].map((field, index) => {
								const extraInputProps = get(field, "extraInputProps", {});

								const extraProps =
									typeof extraInputProps === "function"
										? extraInputProps(formik)
										: extraInputProps;
								if (
									field.renderCondition &&
									!field.renderCondition(formik.values["propertyType"])
								) {
									return null;
								}
								return (
									<div
										className={`${field.className} mt-3`}
										style={{ width: field.width }}
									>
										<FormLabel text={field.label} required={field.required} />
										{field.key === "address" ? (
											<div className="google-location-field">
												<LocationField
													defaultAddress={formik.values.address}
													onSelect={(payload) => {
														const addressObj = payload.addressObj;
														formik.setFieldValue(
															"propertyLocation",
															payload.locationUrl
														);
														formik.setFieldValue("address", payload.address);
														formik.setFieldTouched("address", true, false);
														formik.setFieldValue(
															"latitude",
															payload.latLng.lat
														);
														formik.setFieldValue(
															"longitude",
															payload.latLng.lng
														);
														for (let key in addressObj) {
															formik.setFieldValue(key, addressObj[key]);
															if (addressObj[key] && addressObj[key] !== "") {
																formik.setFieldTouched(key, true, false);
															}
														}
														// formik.setFieldValue("locationUrl", payload.locationUrl);
													}}
												/>
												{formik.errors["address"] && (
													<FormHelperText
														style={{ fontSize: "12px" }}
														error
														text={formik.errors["propertyLocation"]}
													>
														{formik.errors["propertyLocation"]}
													</FormHelperText>
												)}
											</div>
										) : (
											<TextField
												key={field.key}
												id={field.key}
												type={field.type}
												select={field.select}
												name={field.key}
												fullWidth
												variant="outlined"
												placeholder={field.placeholder}
												className={`registration-formField `}
												value={formik.values[field.key]}
												disabled={field.disabled}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={
													formik.touched[field.key] &&
													Boolean(formik.errors[field.key])
												}
												helperText={
													formik.touched[field.key] && formik.errors[field.key]
												}
												SelectProps={{
													displayEmpty: true,
													multiple: field.multiple,
													renderValue: (selected) => {
														if (field.multiple) {
															if (selected && selected.length === 0) {
																return <em>{field.placeholder}</em>;
															}
															return (
																<Box
																	sx={{
																		display: "flex",
																		flexWrap: "wrap",
																		gap: 0.5,
																		marginTop: "-0.75rem",
																	}}
																>
																	{selected &&
																		selected.length > 0 &&
																		selected.map((value) => (
																			<Chip
																				key={value}
																				label={value}
																				style={{
																					fontSize: "12px",
																				}}
																				variant="outlined"
																				onDelete={() => {
																					formik.setFieldValue(
																						field.key,
																						selected.filter(
																							(item) => item !== value
																						)
																					);
																				}}
																			/>
																		))}
																</Box>
															);
														} else {
															return <Typography>{selected}</Typography>;
														}
													},
												}}
												{...extraProps}
											>
												<MenuItem value={""} disabled>
													{field.placeholder}
												</MenuItem>
												{field.options &&
													field.options.map((option) => {
														return <MenuItem value={option}>{option}</MenuItem>;
													})}
											</TextField>
										)}
									</div>
								);
							})}
							<div data-testid="document-upload" className="mt-3">
								<div className="d-flex flex-wrap col-12 pt-5 mb-3">
									<div className={"col-12 "}>
										{/* <div className="d-flex flex-wrap col-12"> */}
										<h5>Property Documents</h5>

										<FileUpload
											setFilesState={setFilesState}
											setFileLabelsState={setFileLabelsState}
										/>

										{/* Pass the files, labels, upload status, and existing files to FilesList */}
										{propertyId && (
											<FilesList
												files={existingFiles || []}
												fileLabels={fileLabelsState}
												uploadStatus={{}}
											/>
										)}
										{isUploading && (
											<Grid
												container
												spacing={0}
												direction="column"
												alignItems="center"
												justifyContent="center"
											>
												<Box sx={{ width: "100%" }}>
													<Typography variant="body1">
														Uploading Documents
													</Typography>
													<LinearProgress size="3rem" />
												</Box>
											</Grid>
										)}
										{/* </div> */}
									</div>
								</div>
							</div>
							<div>
								<Typography variant="body1" className="mt-3 ">
									<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
									<strong>Required Fields</strong>
								</Typography>
							</div>
							<div style={{ textAlign: "right" }}>
								<div className="d-flex justify-content-center">
									<div>
										<button
											type="submit"
											className={`save-details-button m-4 outlinedButton`}
											onClick={() => {
												onCancel();
											}}
										>
											{"Cancel"}
										</button>
									</div>
									<div className="float-right">
										<button
											type="submit"
											className={`save-details-button m-4 primaryButton`}
										>
											{propertyId ? "Save Changes" : "Create Property"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewProperty;
