import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
	TextField,
	Box,
	Typography,
	IconButton,
	LinearProgress,
	Grid,
	CircularProgress,
	Autocomplete,
	createFilterOptions,
} from "@mui/material";
import { addJobForm } from "./addJobUtils";
import get from "lodash/get";
import { CloseOutlined } from "@mui/icons-material";
import DatePickerField from "components/DateComponets/DatePicker";
import {
	addUpdateJob,
	fetchJobStatus,
	fetchJobtypes,
} from "actions/jobActions";
import { fetchUsers } from "actions/userActions";
import { fetchFiles } from "actions/documentsActions";
import {
	selectAllFiles,
	selectIsUploading,
} from "selectors/documentsSelectors";
import { getUsersList, getUsersLoading } from "selectors/userSelector";
import { getJobDynamicOptions } from "selectors/jobSelectors";
import FilesList from "components/FileList/FileList";
import FileUpload from "components/FileUpload/FileUpload";
import FormInputField, {
	FormLabel,
} from "components/TextFields/FormInputField";
import dayjs from "dayjs";

const filter = createFilterOptions();

export const AddNewJob = ({
	onCancel = () => {},
	jobId,
	selectedJob,
	title,
	projectId,
	propData,
	onSave = () => {},
}) => {
	const dispatch = useDispatch();

	const { formFields, validationSchema } = addJobForm;
	const initialState = addJobForm.defaultState(get(selectedJob, "data", null));

	//Files Upload and Retrival
	const [filesState, setFilesState] = React.useState([]);
	const [fileLabelsState, setFileLabelsState] = React.useState({});

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);

	const existingFiles = useSelector(selectAllFiles);

	const isUploading = useSelector(selectIsUploading);

	const usersList = useSelector(getUsersList);
	const dynamicOptions = useSelector(getJobDynamicOptions);
	const usersLoading = useSelector(getUsersLoading);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			dispatch(
				addUpdateJob({
					formData: { ...formik.values, ...projectId },
					filesData: { filesState, fileLabelsState },
					onSuccess: (payload) => {
						// navigate("/jobs");
						onCancel(false);
						if (onSave) {
							onSave(payload);
						}
					},
					payload: propData,
				})
			);
		},
	});

	const fetchExistingDocs = useCallback(() => {
		console.log("Check the JobId for fetchExistingDocs: ", jobId);
		if (jobId) {
			dispatch(fetchFiles("Job", jobId));
		}
	}, [dispatch, jobId]);

	useEffect(() => {
		fetchExistingDocs();
	}, [fetchExistingDocs]);

	useEffect(() => {
		dispatch(fetchUsers());
		dispatch(fetchJobStatus());
		dispatch(fetchJobtypes());
	}, [dispatch, jobId]);

	return (
		<div className="mt-2 p-2">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h1" id="header">
					{title || "Create New Job"}
				</Typography>
				<IconButton onClick={() => onCancel(false)}>
					<CloseOutlined />
				</IconButton>
			</div>
			<div className="">
				<div>
					<p className="m-1">
						<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
						<b>
							{
								"Please enter all the details of the job that are required to be filled."
							}
						</b>
					</p>
				</div>
				<div>
					<form onSubmit={formik.handleSubmit}>
						<div className="stepContent container row">
							{formFields.map((field, index) => {
								const extraProps = get(field, "extraInputProps", {});

								if (field.type === "dateField") {
									return (
										<div
											className={`form-field-wrapper ${field.className}`}
											style={{ width: field.width }}
										>
											<FormLabel text={field.label} required={field.required} />
											<DatePickerField
												onChange={(date) => {
													if (
														!date ||
														dayjs(date).isValid() === false ||
														dayjs(date).year() === 1970
													) {
														formik.setFieldValue(field.key, null); // Explicitly set to null
													} else {
														formik.setFieldValue(field.key, date);
													}
												}}
												onClear={() => {
													formik.setFieldValue(field.key, null);
												}}
												value={formik.values[field.key] || null}
												field={field}
												slotProps={{
													field: {
														clearable: !field.required,
														onClear: () => {
															formik.setFieldValue(field.key, null);
														},
													},
												}}
												{...extraProps}
											/>
										</div>
									);
								}
								if (field.type === "dynamicField") {
									return (
										<div
											className={`form-field-wrapper ${field.className}`}
											style={{ width: field.width }}
										>
											<div className="d-flex justify-content-between">
												<FormLabel
													text={field.label}
													required={field.required}
												/>
												<div>
													<Typography
														variant="body1"
														style={{
															color: "#345DAE",
															cursor: "not-allowed",
															fontWeight: "600",
														}}
													>
														+ Add Supervisor
													</Typography>
												</div>
											</div>{" "}
											<Autocomplete
												onChange={(_, value) => {
													let inputValue = "";
													// Handle different value types
													if (typeof value === "string") {
														inputValue = value;
													} else if (value && value.inputValue) {
														// Handle the "Add new" option
														inputValue = value.inputValue;
													} else if (value) {
														// Handle selected user object
														inputValue =
															`${value.firstName} ${value.lastName}`.trim();
													}
													formik.setFieldValue(field.key, inputValue);
												}}
												id="asynchronous-demo"
												creatable
												value={
													usersList.find(
														(user) =>
															`${user.firstName} ${user.lastName}`.trim() ===
															formik.values[field.key]
													) || formik.values[field.key]
												}
												className="form-field autocomplete-field"
												open={autoCompleteOpen}
												onOpen={() => setAutoCompleteOpen(true)}
												onClose={() => setAutoCompleteOpen(false)}
												isOptionEqualToValue={(option, value) => {
													if (typeof value === "string") {
														return (
															`${option.firstName} ${option.lastName}`.trim() ===
															value
														);
													}
													return option.userId === value.userId;
												}}
												filterOptions={(options, params) => {
													const filtered = filter(options, params);
													const { inputValue } = params;

													// Check if input matches any existing option
													const isExisting = options.some(
														(option) =>
															inputValue ===
																`${option.firstName} ${option.lastName}`.trim() ||
															inputValue === option.title
													);

													// Add the "create new" option if input is valid and doesn't exist
													if (inputValue.trim() !== "" && !isExisting) {
														filtered.push({
															inputValue: inputValue.trim(),
															title: `Add "${inputValue.trim()}"`,
															firstName: "",
															lastName: "",
														});
													}

													return filtered;
												}}
												getOptionLabel={(option) => {
													if (typeof option === "string") {
														return option;
													}
													if (option.inputValue) {
														return option.inputValue;
													}
													return `${option.firstName} ${option.lastName}`.trim();
												}}
												renderOption={(props, option) => {
													const { key, ...optionProps } = props;
													return (
														<li key={option.userId || "new"} {...optionProps}>
															{option.title ||
																`${option.firstName} ${option.lastName}`.trim()}
														</li>
													);
												}}
												options={usersList}
												loading={usersLoading}
												renderInput={(params) => (
													<TextField
														{...params}
														className="form-field"
														onChange={(event) => {
															const textLen = event.target.value.length;
															if (textLen >= 3) {
																// Trigger search/fetch
																console.log("Fetching the record");
															}
														}}
														InputProps={{
															...params.InputProps,
															endAdornment: (
																<React.Fragment>
																	{usersLoading ? (
																		<CircularProgress
																			color="inherit"
																			size={20}
																		/>
																	) : null}
																	{params.InputProps.endAdornment}
																</React.Fragment>
															),
														}}
													/>
												)}
											/>
										</div>
									);
								}
								return (
									<FormInputField
										field={{
											...field,
											options: dynamicOptions[field.key] || field.options,
										}}
										formik={formik}
										value={formik.values[field.key]}
										key={field.key}
										id={field.id}
										{...extraProps}
									/>
								);
							})}
							<div data-testid="document-upload" className="mt-3">
								<div className="d-flex flex-wrap col-12 pt-5 mb-1">
									<div className={"col-12 "}>
										{/* <div className="d-flex flex-wrap col-12"> */}
										<h5>Job Documents</h5>

										<FileUpload
											setFilesState={setFilesState}
											setFileLabelsState={setFileLabelsState}
										/>
										{/* Pass the files, labels, upload status, and existing files to FilesList */}
										{jobId && (
											<FilesList
												files={existingFiles || []}
												fileLabels={fileLabelsState}
												uploadStatus={{}}
											/>
										)}
										{isUploading && (
											<Grid
												container
												spacing={0}
												direction="column"
												alignItems="center"
												justifyContent="center"
											>
												<Box sx={{ width: "100%" }}>
													<Typography variant="body1">
														Uploading Documents
													</Typography>
													<LinearProgress size="3rem" />
												</Box>
											</Grid>
										)}
									</div>
								</div>
							</div>
							<div>
								<Typography variant="body1" className="mt-1 ">
									<sup style={{ color: "red", fontSize: "1em", top: 0 }}>*</sup>
									<strong>Required Fields</strong>
								</Typography>
							</div>
							<div className="mt-2">
								<div className="d-flex justify-content-center align-self-center">
									<button
										type="button"
										className={`secondaryButton m-3`}
										onClick={() => {
											onCancel();
										}}
									>
										{"Cancel"}
									</button>
									<button
										type="submit"
										className={`primaryButton m-3`}
										style={{ width: "auto" }}
									>
										{jobId ? "Save Changes" : "Create Job"}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewJob;
