import React from "react";
import { getText } from "core/utils/getContentText";
import contractorImg from "assets/loginRegistration/general-contractor.png";
import supplierImg from "assets/loginRegistration/general-supplier.png";
import ownerImg from "assets/loginRegistration/house-owner.png";

export const SSO_RegisterTypeModel = ({
	handleClose = () => {},
	setUserType,
}) => {
	const signUpTypes = [
		{
			key: "contractor",
			text: getText("registerType.contractor"),
			image: contractorImg,
		},
		// {
		// 	key: "supplier",
		// 	text: getText("registerType.supplier"),
		// 	image: supplierImg,
		// },
		{
			key: "homeOwner",
			text: getText("registerType.homeOwner"),
			image: ownerImg,
		},
	];

	return (
		<>
			<div>
				<div className="registration-type-container">
					<div className="flex-container row select-box-container">
						{signUpTypes.map((type) => {
							return (
								<div
									key={`register-type-${type.key}`}
									className="register-type-selectBox hoverEffect col"
									onClick={() => {
										setUserType(type.key);
										handleClose(false);
										window.scrollTo(0, 0);
									}}
								>
									<img
										src={type.image}
										alt={type.key}
										className={`register-select-img ${type.key}`}
									/>
									<p className="subtitle1 text-center select-text">
										{getText("registerType.registerAs")}
										<br />
										{type.text}
									</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default SSO_RegisterTypeModel;
