import React from "react";
import { Projects } from "routes/loadables";

export const PropertyOverview = (props) => {
	return (
		<div id="property-overview" style={{ background: "#F2F2F2" }}>
			<div
				id="projects-list"
				className="m-2 mt-0 position-relative"
				style={{ top: "1rem" }}
			>
				<Projects {...props} />
			</div>
		</div>
	);
};

export default PropertyOverview;
