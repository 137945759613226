import React, { useEffect, useRef } from "react";
import { AgChartsReact } from "ag-charts-react";
import { Hidden } from "@mui/material";
import reverse from "lodash/reverse";
import "../projects.scss";
import ProjectTimelineChart from "./ProjectOverviewTimeline";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectOverview } from "actions/projectActions";
import { getProjectOverview } from "selectors/projectSelector";
import { get } from "lodash";

const localprojectOverviewData = {
	// monthlyExpensesData: [
	// 	{ month: "Jan", value: 40000 },
	// 	{ month: "Feb", value: 80000 },
	// 	{ month: "Mar", value: 60000 },
	// 	{ month: "Apr", value: 100000 },
	// 	{ month: "May", value: 70000 },
	// 	{ month: "Jun", value: 120000 },
	// 	{ month: "Jul", value: 110000 },
	// 	{ month: "Aug", value: 130000 },
	// 	{ month: "Sep", value: 160000 },
	// 	{ month: "Oct", value: 70000 },
	// 	{ month: "Nov", value: 50000 },
	// 	{ month: "Dec", value: 60000 },
	// ],
	// projectTimeline: [
	// 	{
	// 		activity: "Project Start",
	// 		startDate: "2024-01-01",
	// 		endDate: "2024-02-28",
	// 	},
	// 	{ activity: "Excavation", startDate: "2024-02-01", endDate: "2024-03-30" },
	// 	{ activity: "Foundation", startDate: "2024-03-01", endDate: "2024-05-30" },
	// 	{ activity: "Framing", startDate: "2024-05-01", endDate: "2024-06-30" },
	// 	{ activity: "Plumbing", startDate: "2024-06-01", endDate: "2024-07-30" },
	// 	{ activity: "Electrical", startDate: "2024-07-01", endDate: "2024-08-30" },
	// 	{ activity: "Drywall", startDate: "2024-08-01", endDate: "2024-09-30" },
	// 	{ activity: "Painting", startDate: "2024-09-01", endDate: "2024-10-30" },
	// 	{ activity: "Finishing", startDate: "2024-10-01", endDate: "2024-11-30" },
	// ],
};

export const ProjectOverview = ({ projectId }) => {
	const cardRef = useRef(null);
	const dispatch = useDispatch();

	const overviewData = useSelector(getProjectOverview);

	const projectOverviewData = {
		...overviewData,
		// ...localprojectOverviewData,
	};

	useEffect(() => {
		dispatch(fetchProjectOverview(projectId));
	}, [projectId, dispatch]);

	const containerHeight = 244;

	const budgetSpentOptions = {
		data: [
			{ asset: "Stocks", amount: 6.8 },
			{ asset: "Remaining", amount: 93.2 },
		],
		height: containerHeight, //180,
		width: containerHeight,
		background: {
			visible: false,
		},
		series: [
			{
				type: "donut",
				// calloutLabelKey: "asset",
				angleKey: "amount",
				innerRadiusRatio: 0.8,
				strokeWidth: 0,
				fills: ["#345DAE", "#DDDDDD"],
				strokes: ["#FFFFFF"],
				innerLabels: [
					{
						text: "Budget Spent",
						fontFamily: "system-ui",
						fontSize: 14,
						fontWeight: "500",
						color: "#1A1A1A",
						margin: 0, // Top margin for first label
					},
					{
						text: "6.8%",
						fontFamily: "system-ui",
						fontSize: 36,
						fontWeight: "bold",
						color: "#345DAE",
						margin: 16, // Increased margin to create more space between labels
					},
				],
				innerCircle: {
					fill: "#F2F2F2",
				},
				calloutLabel: {
					enabled: false,
				},
				tooltip: {
					enabled: false,
				},
			},
		],
		padding: {
			top: 10,
			bottom: 10,
			left: 10,
			right: 10,
		},
	};
	return (
		<div className="container-fluid p-4">
			<Hidden mdDown>
				<div className="mt-4" style={{ placeSelf: "center" }}>
					<div
						className="donut-chart-overlay"
						style={{
							marginTop: "5rem",
							height: containerHeight,
							width: containerHeight,
						}}
					>
						<div
							style={{
								width: `${containerHeight}`,
								height: `${containerHeight}`,
							}}
						>
							<AgChartsReact options={budgetSpentOptions} />
						</div>
					</div>
				</div>
			</Hidden>
			<div>
				{/* Metrics grid */}
				<div
					className="relative row g-4 "
					ref={cardRef}
					style={{ position: "relative" }}
				>
					{/* Metric cards */}

					<div className="col-md-6">
						<div className="card h-100">
							<div className="card-body">
								<div className="d-flex flex-wrap justify-content-sm-between">
									{get(projectOverviewData, "expenditureBudget", []).map(
										(expBudget) => {
											return (
												<div className="col-lg-6 col-12">
													<h3
														className="card-title h6 mb-2 ms-0 dark-blue"
														style={{ fontSize: "18px" }}
													>
														{expBudget.text}
													</h3>
													<p className="card-value mb-0 main-blue">
														{expBudget.prefix}
														{expBudget.value?.toLocaleString()}
														{expBudget.suffix}
													</p>
												</div>
											);
										}
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className="card h-100">
							<div className="card-body ">
								<div className="d-flex flex-wrap justify-content-lg-end justify-content-sm-start">
									{get(projectOverviewData, "budgetMetrics", []).map(
										(expBudget) => {
											return (
												<div className="d-grid justify-content-lg-end justify-content-start col-lg-6 col-12">
													<h3
														className="card-title h6 mb-2 ms-0 dark-blue"
														style={{ fontSize: "18px" }}
													>
														{expBudget.text}
													</h3>
													<p className="card-value mb-0 main-blue">
														{expBudget.prefix}
														{expBudget.value?.toLocaleString()}
														{expBudget.suffix}
													</p>
												</div>
											);
										}
									)}
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6 col-12">
						<div className="card h-100">
							<div className="card-body mt-4">
								<h3 className="card-title h6 mb-2 ms-0">Project Expenses</h3>
								<div>
									<AgChartsReact
										options={{
											height: 400, // Increased height for better visibility
											data: get(projectOverviewData, "monthlyExpensesData", []),
											series: [
												{
													type: "line",
													xKey: "month",
													yKey: "value",
													stroke: "#345DAE", // Added professional color
													strokeWidth: 2,
													marker: {
														fill: "#FAAF40",
														size: 10, // Reduced size for better appearance
														stroke: "#ffffff",
														strokeWidth: 0,
														shape: "circle",
													},
												},
											],
											axes: [
												{
													type: "category",
													position: "bottom",
													title: {
														text: "Month",
													},
													label: {
														fontSize: 12,
														color: "#8B8B8B",
													},
												},
												{
													type: "number",
													position: "left",
													title: {
														text: "Expenses", // Added meaningful title
													},
													label: {
														color: "#000000",
														fontWeight: 600,
														formatter: function (params) {
															// Changed to regular function for better compatibility
															if (params.value === 0) return "$0";
															return `$${params.value / 1000}K`; // Removed React element for compatibility
														},
													},
												},
											],
											// title: {
											// 	text: "Monthly Revenue",
											// 	fontSize: 18,
											// },
											background: {
												fill: "#ffffff",
											},
											padding: {
												top: 40,
												right: 40,
												bottom: 40,
												left: 40,
											},
										}}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6 col-12">
						<div className="card h-100">
							<div className="card-body mt-4">
								<h3 className="card-title h6 mb-2 ms-0">Remaining Budget</h3>
								<AgChartsReact
									options={{
										height: 400, // Increased height for better visibility
										data: get(
											projectOverviewData,
											"monthlyRemainingBudget",
											[]
										),
										series: [
											{
												type: "line",
												xKey: "month",
												yKey: "value",
												stroke: "#345DAE", // Added professional color
												strokeWidth: 2,
												marker: {
													fill: "#FAAF40",
													size: 10, // Reduced size for better appearance
													stroke: "#ffffff",
													strokeWidth: 0,
													shape: "circle",
												},
											},
										],
										axes: [
											{
												type: "category",
												position: "bottom",
												title: {
													text: "Month",
												},
												label: {
													fontSize: 12,
													color: "#8B8B8B",
												},
											},
											{
												type: "number",
												position: "left",
												title: {
													text: "Budget", // Added meaningful title
												},
												label: {
													color: "#000000",
													fontWeight: 600,
													formatter: function (params) {
														// Changed to regular function for better compatibility
														if (params.value === 0) return "$0";
														return `$${params.value / 1000}K`; // Removed React element for compatibility
													},
												},
											},
										],
										// title: {
										// 	text: "Monthly Revenue",
										// 	fontSize: 18,
										// },
										background: {
											fill: "#ffffff",
										},
										padding: {
											top: 40,
											right: 40,
											bottom: 40,
											left: 40,
										},
									}}
								/>
							</div>
						</div>
					</div>
					<div>
						<div className="card h-100">
							<div className="card-body mt-4">
								<h3 className="card-title h6 mb-2 ms-0">Key Activity Status</h3>
								<div>
									<ProjectTimelineChart
										projectTimeline={projectOverviewData.projectTimeline || []}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ProjectOverview;
