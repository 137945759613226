import React, { useState, useEffect, useCallback } from "react";
import RenderTabs from "components/Tabs/Tabs";
import ViewPropertyDetails from "./PropertyDetails";
import { ArrowBack } from "@mui/icons-material";
import { Navigate, useParams } from "react-router-dom";
import { getPropertyById } from "actions/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyData } from "selectors/propertySelectors";
import { Typography } from "@mui/material";
import { omit } from "lodash";
import { CustomBreadCrumb } from "components/BreadCrumb";
import PropertyOverview from "./PropertyOverview";

const tabs = [
	{
		key: "overview",
		label: "Overview",
		TabComponent: PropertyOverview,
		tabProps: { hideTitle: false },
	},
	{
		key: "propertyDetails",
		label: "Property Details",
		TabComponent: ViewPropertyDetails,
	},
];

export const ViewProperty = () => {
	const [selectedTab, updateTab] = useState("overview");
	const params = useParams();
	const dispatch = useDispatch();

	const propertyRawData = useSelector(getPropertyData);
	const propertyData = omit(propertyRawData, ["projectHeaderList"]);

	const getPropertyDetails = useCallback(() => {
		dispatch(getPropertyById(params.propertyId));
	}, [dispatch, params.propertyId]);

	useEffect(() => {
		getPropertyDetails();
	}, [getPropertyDetails]);

	return (
		<>
			<div className="d-flex align-items-center">
				{/* <button
					style={{ height: "2rem", border: "none" }}
					className="secondaryButton mt-3"
				> */}
				<ArrowBack
					style={{ height: "20px", width: "23px", color: "#345DAE" }}
					className="pointer mt-2"
					onClick={() => window.history.back()}
				/>
				{/* </button> */}
				<Typography
					className="h3 mt-3"
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						width: "15rem",
					}}
				>
					{propertyData?.propertyName}
				</Typography>
			</div>
			<CustomBreadCrumb breadCrumbItems={["properties", "propertyName"]} />

			<div className="project-tabs tabs">
				<RenderTabs
					className="tab-list"
					tabs={tabs}
					selectedTab={selectedTab}
					onTabClick={(newTab) => {
						updateTab(newTab);
					}}
					tabParams={{
						propertyId: params.propertyId,
						projectId: params.propertyId,
						propertyData,
						fromProperty: true,
					}}
				/>
			</div>
			<div className="project-tab-content"></div>
		</>
	);
};

export const RedirectToProperty = () => {
	const { propertyId } = useParams();
	return <Navigate to={`/properties/${propertyId}`} replace />;
};

export default ViewProperty;
