import React, { useState } from "react";
import {
	TextField,
	Box,
	Typography,
	IconButton,
	Grid,
	Paper,
} from "@mui/material";
import {
	CloudUpload as CloudUploadIcon,
	Delete as DeleteIcon,
	FileUploadOutlined,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone"; // Import Dropzone

const FileUpload = ({
	handleFileUpload,
	setFilesState = () => {},
	setFileLabelsState = () => {},
	instantUpload = false,
	showUploadCancelButtons = false,
	onUploadButtonClick = () => {},
}) => {
	const [files, setFiles] = useState([]);
	const [fileLabels, setFileLabels] = useState({});
	const [uploadStatus, setUploadStatus] = useState({});
	const [previewFile, setPreviewFile] = useState(null); // State for file preview

	const allowedFileTypes = [
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.ms-powerpoint",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"text/plain",
		"text/csv",
		"image/png",
		"image/jpeg",
		"image/jpg",
		"application/vnd.dwg",
		"application/vnd.dxf",
		"application/vnd.dgn",
		"message/rfc822", // Email files (.eml)
		"application/zip", // Email attachments
	];

	const updateFilesState = (state) => {
		setFiles(state);
		setFilesState(state);
	};
	const updateLabelsState = (state) => {
		setFileLabels(state);
		setFileLabelsState(state);
	};

	// Handle file selection from both the button and drag-and-drop
	const handleFileSelection = (selectedFiles) => {
		const newFiles = selectedFiles.filter(
			(file) =>
				file.size < 10 * 1024 * 1024 && // Ensure file size < 10MB
				allowedFileTypes.includes(file.type)
		);

		if (newFiles.length !== selectedFiles.length) {
			alert("Some files were rejected due to size or unsupported format.");
		}

		const newUploadStatus = {};
		newFiles.forEach((file, index) => {
			newUploadStatus[index] = "uploading"; // Initially mark file as uploading
			//simulateUpload(file, fileIndex); // Start upload simulation
			//handleFileUpload(file);
		});

		updateFilesState((prevFiles) => [...newFiles, ...prevFiles]);

		setUploadStatus((prevStatus) => ({ ...newUploadStatus, ...prevStatus }));
	};

	// Handle label change
	const handleLabelChange = (event, index) => {
		const { value } = event.target;
		updateLabelsState((prevLabels) => ({
			...prevLabels,
			[index]: value,
		}));
		// setFileLabels();
	};

	const removeFile = (index) => {
		updateLabelsState((prevLabels) => {
			const newLabels = { ...prevLabels };
			delete newLabels[index];
			return newLabels;
		});
		const newState = files.filter((_, i) => i !== index);
		updateFilesState(newState);
	};

	const handleUploadFile = (index) => {
		if (instantUpload) {
			handleFileUpload(files[index], fileLabels[index]);
		}
		removeFile(index);
	};

	const onCancel = () => {
		updateFilesState([]);
		updateLabelsState({});
	};

	// Dropzone configuration for drag-and-drop
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles) => handleFileSelection(acceptedFiles),
		accept: allowedFileTypes.join(","),
		maxSize: 10 * 1024 * 1024, // Max file size of 10MB
	});

	const onUploadSuccessCallback = () => {
		onCancel();
	};

	return (
		<Box sx={{ margin: "0 auto", padding: 2 }}>
			<div {...getRootProps()} style={{ marginBottom: "16px" }}>
				<Paper
					elevation={isDragActive ? 6 : 2}
					sx={{
						padding: 3,
						border: isDragActive ? "2px dashed #3f51b5" : "2px dashed #ccc",
						textAlign: "center",
						backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
						cursor: "pointer",
					}}
				>
					<input {...getInputProps()} />
					{isDragActive ? (
						<Typography variant="h6" color="primary">
							Drop the files here...
						</Typography>
					) : (
						<Typography
							variant="h6"
							style={{ fontSize: "16px", color: "#8B8B8B" }}
						>
							Drop Files to upload <FileUploadOutlined />
							<div>
								(PDF, DOC, DOCX, XLS, XLSX, JPG, PNG, or GIF) Max File Size:
								10MB
							</div>
						</Typography>
					)}
				</Paper>
			</div>

			<br />

			{files.length > 0 && (
				<Box mt={2}>
					<Grid container spacing={2}>
						{files.map((file, index) => (
							<Grid item xs={12} md={8} key={index}>
								<Box display="flex" alignItems="center">
									<Box width="40%">
										<Typography noWrap>{file.name}</Typography>
									</Box>
									<Box width="40%" mx={2}>
										<TextField
											placeholder="Enter FileName"
											className="register-form"
											variant="outlined"
											size="small"
											value={fileLabels[index] || ""}
											onChange={(event) => handleLabelChange(event, index)}
											fullWidth
										/>
									</Box>
									{instantUpload && (
										<Box>
											<IconButton
												color="primary"
												onClick={() => handleUploadFile(index)}
											>
												<CloudUploadIcon
													style={{ fontSize: "14px", color: "blue" }}
												/>
											</IconButton>
										</Box>
									)}
									<Box>
										<IconButton
											color="primary"
											onClick={() => removeFile(index)}
										>
											<DeleteIcon
												style={{ fontSize: "14px", color: "black" }}
											/>
										</IconButton>
									</Box>
								</Box>
							</Grid>
						))}
					</Grid>
				</Box>
			)}
			{showUploadCancelButtons && files.length > 0 && (
				<div className="upload-files-bnt-container d-flex justify-content-center mt-3">
					<button className="outlinedButton m-2" onClick={() => onCancel()}>
						Cancel
					</button>
					<button
						className="primaryButton m-2"
						onClick={() => onUploadButtonClick(onUploadSuccessCallback)}
					>
						Upload
					</button>
				</div>
			)}
		</Box>
	);
};

export default FileUpload;
