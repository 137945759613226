import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { jwtDecode } from "jwt-decode";
import {
	faSpinner,
	faCheck,
	faClock,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { DATE_FORMAT } from "constants/appConstants";

export const getListOfPastYears = (
	pastCount = 4,
	includeCurrentYear = true
) => {
	let currentYear = new Date().getFullYear();
	const pastYears = [];
	currentYear = includeCurrentYear ? currentYear : currentYear - 1;

	for (let i = 0; i < pastCount; i++) {
		pastYears.push(currentYear - i);
	}

	return pastYears;
};

export const isActivateUserSignIn = () => {
	const decodedTokenData = decodeToken();
	const currentTime = Math.floor(Date.now() / 1000);
	return decodedTokenData && decodedTokenData.exp >= currentTime;
};

export const decodeToken = () => {
	const token = localStorage.getItem("token");
	if (token) {
		return jwtDecode(token);
	}
	return null;
};

export function convertCamelToNormal(camelCaseString) {
	// Split the camelCaseString by finding all capital letters preceded by a lowercase letter
	// This regex matches positions where a lowercase letter is followed by an uppercase letter
	let words = camelCaseString.split(/(?=[A-Z])/);

	// Capitalize each word
	for (let i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
	}

	// Join the words into a single string with spaces in between
	let normalCaseString = words.join(" ");

	return normalCaseString;
}
export function calculateProjectStatusCounts(projects, key = "stage") {
	// Initialize an object to store the counts
	const statusCounts = {
		Pending: 0,
		Completed: 0,
		"In Progress": 0,
		Planning: 0,
	};

	// Count the projects for each status
	projects.forEach((project) => {
		if (statusCounts.hasOwnProperty(project[key])) {
			statusCounts[project[key]]++;
		} else {
			// If we encounter a status not in our initial object, we can add it here
			statusCounts[project[key]] = 1;
		}
	});

	return statusCounts;
}

export const getStatus = (status) => {
	if (status === "InProgress") {
		return getStatusIcon(faSpinner, "#FFA500", "1.5rem", status);
	}
	if (status === "Planning" || status === "Pending" || status === "waiting") {
		return getStatusIcon(faClock, "#FFA500", "1.5rem", status);
	}
	if (status === "Completed" || status === "connected") {
		return getStatusIcon(faCheck, "#008000", "1.5rem", status);
	}
	if (status === "Identified") {
		return getStatusIcon(faTriangleExclamation, "#FF0000", "1.5rem", status);
	}
};

export const getStatusIcon = (icon, color, size, matchStatus) => (
	<FontAwesomeIcon
		icon={icon}
		color={color}
		size={size || "1.5rem"}
		data-testid={`${matchStatus}-icon`}
	/>
);

export const projectStatusOptions = [
	"Contracted",
	"Waiting on Permits",
	"In Progress",
	"Waiting on Materials",
	"Waiting for Supplier",
	"Waiting Punchlist",
	"Completed",
];

export const calculateTimeAgoText = (dateString) => {
	const now = new Date();
	const past = new Date(dateString);
	const diffInSeconds = Math.floor((now - past) / 1000);

	if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
	if (diffInSeconds < 3600)
		return `${Math.floor(diffInSeconds / 60)} minutes ago`;
	if (diffInSeconds < 86400)
		return `${Math.floor(diffInSeconds / 3600)} hours ago`;
	if (diffInSeconds < 604800)
		return `${Math.floor(diffInSeconds / 86400)} days ago`;
	if (diffInSeconds < 2592000)
		return `${Math.floor(diffInSeconds / 604800)} weeks ago`;
	if (diffInSeconds < 31536000)
		return `${Math.floor(diffInSeconds / 2592000)} months ago`;
	return `${Math.floor(diffInSeconds / 31536000)} years ago`;
};

export const createDownloadFile = (blobContent, file) => {
	// Create download link and trigger download
	const downloadUrl = window.URL.createObjectURL(blobContent);
	const link = document.createElement("a");
	link.href = downloadUrl;
	link.download = file.fileName;

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);

	window.URL.revokeObjectURL(downloadUrl);
};

export const getMomentDate = (date, format = DATE_FORMAT) => {
	if (moment(date).isValid()) {
		return moment(date).format(format);
	} else {
		return "NA";
	}
};

export const scrollToTop = () => {
	window.scrollTo(0, 0);
};

export const scrollToMiddle = () => {
	window.scrollTo(0, window.innerHeight / 2);
};

export const scrollToBottom = () => {
	window.scrollTo(0, document.body.scrollHeight);
};

export const serializeStringToJson = (str) => {
	// Function to convert string to JSON
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};
