import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import queryString from "query-string";

export const fetchUsers = (params) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_USERS,
				payload: {
					loading: true,
				},
			});
			const queryParams = queryString.stringify(params);
			request({
				method: "GET",
				url: `${server_url}/user${queryParams ? `?${queryParams}` : ""}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_USERS_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_USERS_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchProjRoles = () => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_PROJECT_ROLES,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/projRole`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_PROJECT_ROLES_SUCCESS,
						payload: {
							response: response.data.items,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_PROJECT_ROLES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchProjUserRoles = (projectRefId) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_PROJECT_USERS_ROLES,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/projUserRole${
					projectRefId ? `?projectId=${projectRefId}` : ""
				}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_PROJECT_USERS_ROLES_SUCCESS,
						payload: {
							response: response.data.items,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_PROJECT_USERS_ROLES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const deleteProjUserRole = (purId, projectId) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.DELETE_PROJECT_USER_ROLE,
				payload: {
					loading: true,
				},
			});
			request({
				method: "DELETE",
				url: `${server_url}/projUserRole/${purId}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.DELETE_PROJECT_USER_ROLE_SUCCESS,
						payload: {
							status: "success",
							message: "Deleted Successfully",
							loading: false,
						},
					});
					dispatch(fetchProjUserRoles(projectId));
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.DELETE_PROJECT_USER_ROLE_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const updateProjUserRoles = (payload) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.UPDATE_PROJECT_USERS_ROLES,
				payload: {
					loading: true,
				},
			});
			request({
				method: payload.purId ? "PUT" : "POST",
				url: `${server_url}/projUserRole${
					payload.purId ? `/${payload.purId}` : ""
				}`,
				body: payload,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.UPDATE_PROJECT_USERS_ROLES_SUCCESS,
						payload: {
							response: response.data.items,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					dispatch(fetchProjUserRoles(payload.projectId));
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.UPDATE_PROJECT_USERS_ROLES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const inviteNewUser = (emailId, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.INVITE_NEW_USER,
				payload: {
					loading: true,
				},
			});

			request({
				method: "POST",
				url: `${server_url}/generic/sendemailAddUser?eMailAddress=${emailId}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.INVITE_NEW_USER_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					onSuccess({
						response: response.data,
						status: "success",
						message: "Task Added Successfully",
						loading: false,
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.INVITE_NEW_USER_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const addNewUser = (formData, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.ADD_NEW_USER,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/user`,
				body: {
					...formData,
				},
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.ADD_NEW_USER_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					onSuccess();
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.ADD_NEW_USER_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const saveWeeklyTimesheet = (weekLogData, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.SAVE_TIME_SHEET,
				payload: {
					loading: true,
				},
			});
			dispatch({ type: ActionConstants.LOADING_APP });
			request({
				method: "POST",
				url: `${server_url}/timeLog`,
				body: weekLogData,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.SAVE_TIME_SHEET_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					dispatch({ type: ActionConstants.LOADING_APP_SUCCESS });
					onSuccess();
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.SAVE_TIME_SHEET_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({ type: ActionConstants.LOADING_APP_ERROR });
					resolve();
				});
		});
	};
};

export const fetchTimesheetData = (params) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_TIME_SHEET,
				payload: {
					loading: true,
				},
			});
			dispatch({ type: ActionConstants.LOADING_APP });
			const queryParams = queryString.stringify(params);
			request({
				method: "GET",
				url: `${server_url}/timeLog?${queryParams}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_TIME_SHEET_SUCCESS,
						payload: {
							response: response.data.items,
							totalCount: response.data.totalCount,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					fetchTimesheetData({ page: 0, pageSize: 10 });
					dispatch({ type: ActionConstants.LOADING_APP_SUCCESS });
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_TIME_SHEET_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({ type: ActionConstants.LOADING_APP_ERROR });
					resolve();
				});
		});
	};
};

export const fetchInviteDetailsByGuid = (InvGuid, onSuccess = () => {}) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_GUID_INVITE_DETAILS,
				payload: {
					loading: true,
				},
			});
			const queryParams = queryString.stringify(InvGuid);
			request({
				method: "GET",
				url: `${server_url}/user?${queryParams}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_GUID_INVITE_DETAILS_SUCCESS,
						payload: {
							data: response.data,
						},
					});
					onSuccess(
						{
							emailSent: true,
							message: "Invitation link has been sent to the email address",
						},
						true
					);
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					onSuccess({
						status: "error",
						message: "",
						// message: "Something went wrong please try again later",
						loading: false,
					});
					dispatch({
						type: ActionConstants.GET_GUID_INVITE_DETAILS_ERROR,
						payload: {
							status: "error",
							message: "",
							// message: "Something went wrong please try again later",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
