export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const FETCH_FILES_REQUEST = "FETCH_FILES_REQUEST";
export const FETCH_FILES_SUCCESS = "FETCH_FILES_SUCCESS";
export const FETCH_FILES_FAILURE = "FETCH_FILES_FAILURE";

export const BULK_UPLOAD_FILE_REQUEST = "BULK_UPLOAD_FILE_REQUEST";
export const BULK_UPLOAD_FILE_SUCCESS = "BULK_UPLOAD_FILE_SUCCESS";
export const BULK_UPLOAD_FILE_FAILURE = "BULK_UPLOAD_FILE_FAILURE";

export const DOWNLOAD_FILE_REQUEST = "DOWNLOAD_FILE_REQUEST";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";

export const PREVIEW_FILE_REQUEST = "PREVIEW_FILE_REQUEST";
export const PREVIEW_FILE_SUCCESS = "PREVIEW_FILE_SUCCESS";
export const PREVIEW_FILE_FAILURE = "PREVIEW_FILE_FAILURE";
