import React, { useState } from "react";
import DataGridTable from "components/Table/DataGrid";

import get from "lodash/get";
import { taskListColumns } from "pages/task/addTaskUtils";
// import NoResultsFound from "components/NoResults/NoResultsFound";
import AddEditTask from "pages/task/AddNewTask";
import { useDispatch } from "react-redux";
import { taskDelete } from "actions/taskActions";
import { ViewTask } from "routes/loadables";

export const JobTasks = ({
	tasksData = [],
	jobId,
	job,
	hideAddTask = false,
}) => {
	const dispatch = useDispatch();
	const [addNewTask, setAddNewTask] = useState(false);

	// const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });
	const [selectedRows, setSelectedRows] = React.useState([]);

	// eslint-disable-next-line no-unused-vars
	const [editTask, setEditTask] = useState(false);

	const [selectedTask, setSelectedTask] = useState(null);
	const [viewTask, setViewTask] = useState(null);

	if (viewTask) {
		return (
			<div
				style={{ boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}
				className="p-2"
			>
				<ViewTask
					task={selectedTask}
					jobId={jobId}
					job={job}
					projectId={job.projectId}
					onCancel={() => {
						setViewTask(false);
						setSelectedTask(null);
					}}
				/>
			</div>
		);
	}

	return (
		<>
			{" "}
			<div className="tasks-page">
				{addNewTask ? (
					<div
						style={{ boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)" }}
						className="p-2"
					>
						<AddEditTask
							onCancel={() => {
								setAddNewTask(false);
								setEditTask(null);
								setSelectedTask(null);
							}}
							jobId={jobId}
							projectId={job.projectId}
							selectedTask={selectedTask}
							taskId={selectedTask?.id}
							title={get(selectedTask, "data.taskName", null)}
						/>
					</div>
				) : (
					<>
						<div className="tasks-content-section" style={{ width: "98%" }}>
							<DataGridTable
								onRowClick={(row) => {
									setSelectedTask({ id: row.id, data: row });
									setViewTask(true);
								}}
								pagination={false}
								showToolbar={false}
								columns={taskListColumns({
									setEditTask,
									setAddNewTask,
									setSelectedTask,
									onTaskDelete: (taskId) => {
										dispatch(
											taskDelete({
												taskId,
												params: {
													taskId: taskId,
													projectId: job.projectId,
													jobId: job.jobId,
												},
												projectId: job.projectId,
												jobId: jobId,
												onSuccess: () => {},
											})
										);
									},
								})}
								data={tasksData}
								defineRowId={(row) => {
									// return row.taskId;
									return row.taskId;
								}}
								onSelect={(props) => {
									console.log("Selected Rows : ", props);
									setSelectedRows(props);
								}}
								selectedRows={selectedRows}
								onDeleteSelection={() => {
									console.log("Delete Selection : ", selectedRows);
								}}
							/>
						</div>
					</>
				)}

				{!addNewTask && !hideAddTask && (
					<div className="">
						<button
							className="primaryButton addTaskButton"
							style={{ width: "5rem" }}
							onClick={() => {
								setAddNewTask(!addNewTask);
							}}
						>
							{"+ Add Task"}
						</button>
					</div>
				)}
			</div>
		</>
	);
};
export default JobTasks;
