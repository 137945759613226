export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const ADD_NEW_TASK_SUCCESS = "ADD_NEW_TASK_SUCCESS";
export const ADD_NEW_TASK_ERROR = "ADD_NEW_TASK_ERROR";

export const GET_TASK_BY_ID = "GET_TASK_BY_ID";
export const GET_TASK_BY_ID_SUCCESS = "GET_TASK_BY_ID_SUCCESS";
export const GET_TASK_BY_ID_ERROR = "GET_TASK_BY_ID_ERROR";

export const GET_TASK_STATUS = "GET_TASK_STATUS";
export const GET_TASK_STATUS_SUCCESS = "GET_TASK_STATUS_SUCCESS";
export const GET_TASK_STATUS_ERROR = "GET_TASK_STATUS_ERROR";
