import RenderTabs from "components/Tabs/Tabs";
import React, { useCallback, useContext, useEffect } from "react";
import { ProjectOverview, TaskManager, UserManagement } from "./tabComponents";
import { fetchProjectById } from "actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProjectData } from "selectors/projectSelector";
import { Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { isProjectManager } from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import { CustomBreadCrumb } from "components/BreadCrumb";
import ProjectDetails from "./tabComponents/ProjectDetails";
import UnderConstruction from "pages/app/UnderDevelopment";
import { FileManager } from "./tabComponents/FileManager";

const tabs = [
	{
		key: "overview",
		label: "Overview",
		TabComponent: ProjectOverview,
	},
	{
		key: "taskManager",
		label: "Jobs",
		TabComponent: TaskManager,
	},
	{
		key: "schedules",
		label: "Schedule",
		TabComponent: UnderConstruction,
	},
	{
		key: "suppliers",
		label: "Suppliers",
		// TabComponent: Analysis,
		TabComponent: UnderConstruction,
	},
	{
		key: "files",
		label: "Files",
		TabComponent: FileManager,
	},
	{
		key: "invoices",
		label: "Invoices",
		// TabComponent: Analysis,
		TabComponent: UnderConstruction,

		hide: (appRole, projectDetails) => {
			const projectUserRole = projectDetails?.projUserRole;
			return !isProjectManager(appRole, projectUserRole);
		},
	},
	{
		key: "team",
		label: "Team",
		TabComponent: UserManagement,
		// hide: (appRole, projectDetails) => {
		// 	const projectUserRole = projectDetails?.projUserRole;
		// 	return !isProjectManager(appRole, projectUserRole);
		// },
	},
	{
		key: "projectDetails",
		label: "Project Details",
		TabComponent: ProjectDetails,
	},
	// {
	// 	key: "timelineView",
	// 	label: "Timelines",
	// 	TabComponent: ProjectTimelines,
	// },
];

export const ViewProject = () => {
	const appRoleDetails = useContext(AppRoleContext);

	const [selectedTab, updateTab] = React.useState("overview");

	const params = useParams();
	const dispatch = useDispatch();
	const projectData = useSelector(getProjectData);

	const getProjectDetails = useCallback(() => {
		dispatch(fetchProjectById(params.projectId));
	}, [dispatch, params.projectId]);

	useEffect(() => {
		getProjectDetails();
	}, [dispatch, getProjectDetails]);

	return (
		<>
			<div className="d-flex align-items-center">
				<ArrowBack
					style={{ height: "20px", width: "23px", color: "#345DAE" }}
					className="pointer mt-2"
					onClick={() => window.history.back()}
				/>
				<Typography
					className="h3 mt-3"
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
						width: "15rem",
					}}
				>
					{projectData?.projectName}
				</Typography>
			</div>
			<CustomBreadCrumb
				breadCrumbItems={["properties", "propertyName", "projectName"]}
			/>
			<div className="project-tabs tabs" id="tab-container">
				<RenderTabs
					tabs={tabs.filter((tab) =>
						tab.hide ? !tab.hide(appRoleDetails, projectData) : true
					)}
					selectedTab={selectedTab}
					onTabClick={(newTab) => {
						updateTab(newTab);
					}}
					tabParams={{
						projectId: params.projectId,
						projectData,
						getProjectDetails,
					}}
				/>
			</div>
		</>
	);
};

export default ViewProject;
