import React from "react";

// pages
import Dashboard from "pages/dashboard/DashboardNew";

import ContractorBusinessProfile from "pages/profile/ContractorProfile";
// import { loadUserDetails } from "actions/login_registrationActions";
import CompaniesList from "pages/companies/CompaniesList";
import Projects from "pages/projects/Projects";
import AddNewProperty from "pages/property/AddNewProperty";
import Jobs from "pages/job/Jobs";
import PurchaseOrders from "pages/orders/PurchaseOrders";
import Invoice from "pages/invoice/Invoice";
import Payments from "pages/orders/Payments";
// import Tasks from "pages/task/Tasks";
import GanttChart from "pages/GanttChart/GanttChart";
// import Reports from "pages/reportdefinition/Reports";
// import MyReports from "pages/reportdefinition/MyReports";
import ReportDetails from "pages/reportdefinition/ReportDetails";
import ViewReport from "pages/reportdefinition/ViewReport.js";
import ReportColumnDetails from "pages/reportdefinition/ReportColumnDetails.js";
import {
	ViewJob,
	ViewProject,
	ViewTask,
	Timesheet,
	UsersList,
	UnderDevelopment,
	Connections,
	AdminDashboard,
	ViewProperty,
} from "./loadables";
import { Navigate } from "react-router-dom";
import BudgetEstimationTemplate from "pages/estimations/Estimations";
import ReportWrapper from "pages/reportdefinition/ReportWrapper";
import { roleConstants as roles, rolesConfig } from "core/utils/roleUtils";
import SSORegistration from "pages/login/sso_registration/SSO_RegistrationPage";
import { new_routes } from "./new_routes";
import PropertiesFolderView from "pages/property/PropertiesFolderView";

export const externalRoutes = [
	{
		path: "/profile/contractor/businessProfile",
		component: <ContractorBusinessProfile />,
	},
	{
		path: "/sso-login/new-registration",
		component: <SSORegistration />,
	},
];

const builderRoutes = [
	{
		path: "/builder/purchaseOrders",
		component: <PurchaseOrders />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/builder/invoiceOrders",
		component: <Invoice />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/builder/payments",
		component: <Payments />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},

	{
		path: "/builder/estimations",
		component: <BudgetEstimationTemplate />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/builder/connections",
		component: <Connections type={"builder"} />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
];

export const supplierAdminRoutes = [
	{
		path: "/supplier/connections",
		component: <Connections type={"supplier"} />,
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},
];

export const routePaths = [
	{ path: "/", exact: true, component: <Navigate to="/dashboard" /> },
	{ path: "/", component: <Navigate to="/dashboard" /> },
	{ path: "/dashboard", component: <Dashboard /> },
	{
		path: "/admin/dashboard",
		component: <AdminDashboard />,
		roles: roles.SYSTEM_ADMIN,
	},

	{
		path: "/profile/homeOwner/addProperty",
		component: <AddNewProperty />,
	},
	{
		path: "/companies",
		component: <CompaniesList />,
	},
	{
		path: "/properties",
		component: <PropertiesFolderView />,
		// roles: [
		//   roles.BUILDER_ADMIN,
		//   roles.BUILDER_GENERAL_MANAGER,
		//   roles.BUILDER_PROJECT_MANAGER,
		// ],
	},
	{
		path: "/properties/view/:propertyId",
		component: <ViewProperty />,
		roles: [...rolesConfig.adminRoles, ...rolesConfig.propertyViewRole],
	},

	{
		path: "/projects",
		component: <Projects />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.BUILDER_TEAM_MEMBER,
			roles.BUILDER_PROJECT_MANAGER,
			roles.BUILDER_JOB_MANAGER,
			roles.BUILDER_PROJECT_MEMBER,
		],
	},
	{
		path: "/projects/view/:projectId",
		component: <ViewProject />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			...rolesConfig.projectViewRole,
		],
	},
	{
		path: "/jobs",
		component: <Jobs />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.BUILDER_TEAM_MEMBER,
			roles.BUILDER_PROJECT_MEMBER,
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
			roles.SUPPLIER_PROJECT_MANAGER,
			roles.SUPPLIER_JOB_MANAGER,
			roles.SUPPLIER_TEAM_MEMBER,
		],
	},
	{
		path: "/jobs/view/:jobId",
		component: <ViewJob />,
	},
	// {
	// 	path: "/tasks",
	// 	component: <Tasks />,
	// },
	{
		path: "/tasks/view/:taskId",
		component: <ViewTask />,
	},
	{
		path: "/project/:projectId/tasks/:taskId",
		component: <ViewTask />,
	},
	{
		path: "/purchaseOrders",
		component: <PurchaseOrders />,
		roles: rolesConfig.adminRoles,
	},
	{
		path: "/invoiceOrders",
		component: <Invoice />,
		roles: rolesConfig.adminRoles,
	},
	{
		path: "/payments",
		component: <Payments />,
		roles: rolesConfig.adminRoles,
	},
	{
		path: "/timelines",
		component: <GanttChart />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{ path: "/timesheet", component: <Timesheet /> },
	{
		path: "/users",
		component: <UsersList />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
		],
	},
	{
		path: "/estimations",
		component: <BudgetEstimationTemplate />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
		],
	},
	{ path: "/page/underDevelopment", component: <UnderDevelopment /> },
	{
		path: "/reports",
		component: <ReportWrapper type="myReports" />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
		],
	},
	{
		path: "/createreport/:id?",
		component: <ReportDetails />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
		],
	},
	{
		path: "/viewreport/:id",
		component: <ViewReport />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
		],
	},
	{
		path: "/reportcolumn/:id?/:reportid?",
		component: <ReportColumnDetails />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
		],
	},

	...builderRoutes,
	...supplierAdminRoutes,
	...new_routes,
];

export default routePaths;
