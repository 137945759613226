import React, { useState } from "react";
import ProjectJobsList from "./jobs/ProjectJobs";

export const TaskManager = ({ projectId, projectData }) => {
	const [selectedJob, setSelectedJob] = useState(null);

	return (
		<>
			<div>
				<>
					<ProjectJobsList
						projectId={projectId}
						selectedJob={selectedJob}
						setSelectedJob={setSelectedJob}
						projectData={projectData}
					/>
				</>
			</div>{" "}
		</>
	);
};

export default TaskManager;
