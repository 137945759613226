import React from "react";
import "./ListeningAnimation.css";
import { useVoiceAssistantState } from "context/VoiceAssistantContext";

const ListeningAnimation = () => {
  const { VoiceAssistantState, setVoiceAssistantState } =
    useVoiceAssistantState();

  return (
    <div className="listening-container">
      <div
        className="circle-container"
        onClick={() => {
          setVoiceAssistantState({
            ...VoiceAssistantState,
            isListening: false,
          });
        }}
      >
        <div className="circle"></div>
        <div className="circle delay-1"></div>
        <div className="circle delay-2"></div>
      </div>

      <p className="listening-text">Listening...</p>
    </div>
  );
};

export default ListeningAnimation;
