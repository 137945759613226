import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { Box, InputAdornment, LinearProgress, Typography } from "@mui/material";
import {
	AssignmentOutlined,
	Delete,
	EditOutlined,
	VerifiedUser,
} from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import { MenuItem } from "@mui/material";
import HiddenOn from "components/Wrappers/HiddenOn";
import { isJobManager } from "core/utils/roleUtils";
import get from "lodash/get";
import { getMomentDate } from "core/utils/appUtils";
import Tooltip from "components/Tooltip/Tooltip";

const getTextContent = (key) => getText(`jobs.addJobs.${key}`);

export const jobStatusOptions = [
	"Planning",
	"Pending",
	"In Progress",
	"Completed",
];

const addJobValidationSchema = yup.object({
	jobName: yup.string("Enter Job Name").required("Job Name is required"),
	jobTypeId: yup.string("Select Job type").required("Job type is required"),
	jobStatusId: yup
		.string("Select Job Status")
		.required("Job Status is required"),
	estimatedCost: yup
		.number("Enter cost till now")
		.typeError("Please enter a valid Estimation")
		.integer("Cost must be an number")
		.nullable(),
	costToDate: yup
		.number("Enter cost till now")
		.typeError("Please enter a valid cost")
		.integer("Cost must be an number")
		.nullable(),
});
export const addJobForm = {
	formFields: [
		{
			key: "jobName",
			placeholder: "Job Name",
			label: getTextContent("jobName"),
			type: "text",
			required: true,
			width: "100%",
		},
		{
			key: "jobTypeId",
			placeholder: "Select from List",
			label: "Job Type",
			type: "select",
			select: true,
			options: [
				"Plumbing",
				"Electrical",
				"Carpentry",
				"Painting",
				"Masonary",
				"Concrete Finisher",
				"Others",
			],
			required: true,
			width: "50%",
		},
		{
			key: "jobStatusId",
			placeholder: "Select Status",
			label: "Job Status",
			type: "select",
			select: true,
			options: [],
			required: true,
			width: "50%",
		},
		{
			key: "estimatedCost",
			placeholder: "Enter Estimated Cost",
			label: "Estimated Cost",
			type: "text",
			width: "50%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "costToDate",
			placeholder: "Cost till Date",
			label: "Cost to Date",
			type: "text",
			width: "50%",
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "plannedStartDate",
			placeholder: getTextContent("plannedStartDate"),
			label: getTextContent("plannedStartDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "plannedEndDate",
			placeholder: getTextContent("plannedEndDate"),
			label: getTextContent("plannedEndDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "actualStartDate",
			placeholder: getTextContent("actualStartDate"),
			label: getTextContent("actualStartDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "actualEndDate",
			placeholder: getTextContent("actualEndDate"),
			label: getTextContent("actualEndDate"),
			type: "dateField",
			width: "50%",
		},
		{
			key: "assignedTo",
			label: "Assigned To",
			placeholder: "SelectJob assignee",
			type: "select",
			select: true,
			value: "",
			options: [""],
			width: "50%",
		},
		{
			key: "jobSupervisor",
			label: "Job Supervisor",
			placeholder: "Select From List",
			type: "dynamicField",
			value: "",
			select: true,
			options: [""],
			width: "50%",
		},
		{
			key: "jobNotes",
			placeholder: "Enter Job Notes",
			label: "Job Notes",
			type: "textarea",
			width: "100%",
			extraInputProps: {
				multiline: true,
				className:
					"registration-formField project-notes property-details-textarea",
			},
		},
	],
	validationSchema: addJobValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		} else {
			const dynamicInitialState = Object.fromEntries(
				Object.keys(addJobValidationSchema.fields).map((key) => [key, ""])
			);

			return {
				...dynamicInitialState,
				costToDate: "0",
			};
		}
	},
};

export const priority = {
	0: "Low Priority",
	1: "Medium Priority",
	2: "High Priority",
};
export const jobListcolumns = (props = {}) => [
	{
		field: "jobCode",
		headerName: "Job Id",
		width: 100,
		renderCell: (rowData) => {
			return (
				<Tooltip title={rowData.row.jobCode}>
					<Typography
						className="pointer underline-text"
						variant="body2"
						onClick={() => {
							props.setViewJob(rowData.row);
							// props.setSelectedJob({ id: rowData.id, data: rowData.row });
						}}
					>
						{rowData.row.jobCode}
					</Typography>
				</Tooltip>
			);
		},
	},

	{
		field: "jobName",
		headerName: "Job Name",
		width: 200,
		renderCell: (rowData) => {
			return (
				<Typography
					className="pointer underline-text"
					variant="body2"
					onClick={() => {
						props.setViewJob(rowData.row);
						// props.setSelectedJob({ id: rowData.id, data: rowData.row });
					}}
				>
					{rowData.row.jobName}
				</Typography>
			);
		},
	},
	{
		field: "jobSupervisor",
		headerName: "Job Supervisor",
		width: 200,
	},
	{
		field: "assignedTo",
		headerName: "Assigned To",
		width: 200,
	},
	{
		field: "completionPerc",
		headerName: "Completion %",
		width: 100,
		renderCell: (rowData) => {
			const completionStatus = get(rowData, "row.statusComplete", 0);
			return (
				<Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
					<Box sx={{ width: "100%", mr: 1 }}>
						<LinearProgress variant="determinate" value={completionStatus} />
					</Box>
					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" sx={{ color: "text.secondary" }}>
							{`${completionStatus}%`}
						</Typography>
					</Box>
				</Box>
			);
		},
	},

	{
		field: "plannedStartDate",
		headerName: "Start Date",
		valueFormatter: (value) => getMomentDate(value),
	},
	{
		field: "plannedEndDate",
		headerName: "End Date",
		valueFormatter: (value) => getMomentDate(value),
	},
	{
		field: "actions",
		type: "actions",
		flex: 1,
		align: "right",
		getActions: (params) => [
			<BurgerMenu>
				<MenuItem
					onClick={() => {
						props.setEditJob(true);
						props.setAddNewJob(true);
						props.setSelectedJob({ id: params.id, data: params.row });
					}}
				>
					<EditOutlined /> <p className="m-0">Edit Job</p>
				</MenuItem>
				<MenuItem
					onClick={() => {
						props.setAddTask(true);
						props.setSelectedJob({
							id: params.id,
							data: params.row,
						});
					}}
				>
					<AssignmentOutlined /> <p className="m-0">Add Task</p>
				</MenuItem>
				<HiddenOn
					isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
				>
					<MenuItem
						onClick={() => {
							props.setUserManagement(true);
							props.setJobActionsContext(params.id);
						}}
					>
						<VerifiedUser />
						Assign Job
					</MenuItem>
				</HiddenOn>
				<HiddenOn
					isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
				>
					<MenuItem
						onClick={() => {
							props.onJobDelete(params.id, params.row);
						}}
					>
						<Delete /> <p className="m-0">Delete Job</p>
					</MenuItem>
				</HiddenOn>
			</BurgerMenu>,
		],
	},
];
