import React, { useEffect, useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Fab,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
} from "@mui/material";

import voiceAssistantImg from "assets/app/voice-assistant.png";
import {
  Menu as MenuIcon,
} from "@mui/icons-material";

import { useLocation, useNavigate } from "react-router-dom";
import {
  voiceAssistantPath,
  MOBILE_MAX_WIDTH_768,
} from "constants/appConstants";
import { getSidebarOptions } from "components/Sidebar/sidebarUtils";
import { get } from "lodash";
import ListeningAnimation from "components/VoiceAssistantLayout/ListeningAnimation/ListeningAnimation";
import { useVoiceAssistantState } from "context/VoiceAssistantContext";
import WavesListeningAnimation from "components/VoiceAssistantLayout/WavesListeningAnimation/WavesListeningAnimation";

function BottomNav({ userProfile }) {
  const { VoiceAssistantState, setVoiceAssistantState } =
    useVoiceAssistantState();

  const location = useLocation();
  const navigate = useNavigate();

  const { pathname, state = {} } = location;

  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH_768);

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [mainMenuOptions, setMainMenuOptions] = useState([]);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigate = (routeTo) => {
    navigate(routeTo, { state });
    setMenuOpen(false);
  };

  useEffect(() => {
    const getMenuOptions = getSidebarOptions(
      get(userProfile, "role.applUserCategory", "default"),
      get(userProfile, "role.applRoleName", "default")
    );

    if (getMenuOptions.length > 0) {
      const [menuOptionFirst, menuOptionSecond, menuOptionThird] =
        getMenuOptions;

      const menuOptionsFiltered = [menuOptionFirst];

      if (menuOptionSecond && !menuOptionSecond?.label?.includes("Signout")) {
        menuOptionsFiltered.push(menuOptionSecond);
      }

      if (menuOptionThird && !menuOptionThird?.label?.includes("Signout")) {
        menuOptionsFiltered.push(menuOptionThird);
      }

      if (menuOptionsFiltered.length > 1) {
        menuOptionsFiltered.splice(2, 0, {
          label: "Voice Assistant",
          link: `/${voiceAssistantPath}`,
        });
      }

      setMainMenuOptions(menuOptionsFiltered);
    } else {
      if (getMenuOptions.length !== menuOptions.length) {
        setMenuOptions(getMenuOptions);
      }
    }
  }, [userProfile, menuOptions]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        zIndex: 1000,
        display: { xs: "flex", sm: "none" },
        justifyContent: "center",
        // padding: "8px",
      }}
    >
      <BottomNavigation
        sx={{
          width: "100%",
          position: "relative",
          height: "80px",
          paddingTop: 2,
          paddingBottom: 2,
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          // backgroundColor: "#ffffff", // White background
          boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.25)", // Shadow only at the top
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: "-35px", // Adjust to position the cutout
            left: "50%",
            transform: "translateX(-50%)",
            width: "90px", // Diameter of the cutout
            height: "90px",
            // backgroundColor: "#ffffff", // Same as the background color
            borderRadius: "50%", // Creates the circular cutout
            border: "2px solid #e0e0e0", // Add a border
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // Shadow on the bottom half
            zIndex: 1, // Ensures it appears above the BottomNavigation
          },
        }}
      >
        {[
          mainMenuOptions.map((menuItem, index) => (
            <BottomNavigationAction
              key={index}
              label={menuItem.label}
              icon={menuItem.icon}
              onClick={() => handleNavigate(menuItem.link)}
            />
          )),
        ]}

        <BottomNavigationAction
          label="Menu"
          icon={<MenuIcon />}
          onClick={handleMenuToggle}
        />
      </BottomNavigation>

      {VoiceAssistantState.isListening && <WavesListeningAnimation />}

      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "absolute",
          bottom: "43px", // Align it with the cutout
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2, // Ensures it appears above everything
        }}
      >
        <IconButton color="inherit">
          <img
            src={voiceAssistantImg}
            alt="Voice Assistant"
            style={{ width: "5rem" }}
            onClick={() => {
              console.log("BottomNavigationAction", BottomNavigationAction);
              if (!pathname.includes(voiceAssistantPath)) {
                setVoiceAssistantState({
                  ...VoiceAssistantState,
                  isListening: false,
                });

                handleNavigate(`/${voiceAssistantPath}`);
              } else {
                setVoiceAssistantState({
                  ...VoiceAssistantState,
                  isListening: !VoiceAssistantState.isListening,
                });
              }
            }}
          />
        </IconButton>

        <div style={{ position: "relative" }}>
          {pathname.includes(voiceAssistantPath) &&
            VoiceAssistantState.isListening && <ListeningAnimation />}
        </div>
      </Fab>

      <Drawer anchor="right" open={menuOpen} onClose={handleMenuToggle}>
        <Box sx={{ width: 250 }}>
          <List>
            {getSidebarOptions(
              get(userProfile, "role.applUserCategory", "default"),
              get(userProfile, "role.applRoleName", "default")
            ).map((link, index) => (
              <ListItem
                button
                key={link.id}
                onClick={() => handleNavigate(link.navigate)}
              >
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.label} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default BottomNav;
