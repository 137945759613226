import React from "react";
// import createSignalRConnection from "components/Alerts/alertConnection";
import { Divider, Paper, Typography } from "@mui/material";
import { CustomBreadCrumb } from "components/BreadCrumb";
import { Close, FilterList, InfoOutlined } from "@mui/icons-material";
import {
	calculateTimeAgoText,
	serializeStringToJson,
} from "core/utils/appUtils";
import StatusIndicator from "components/StatusIndicators/StatusIndicator_Circle";
import { useSelector } from "react-redux";
import { getAlertNotifications } from "selectors/appSelector";

export const AlertNotifications = () => {
	// const [messages, setMessages] = useState([]);
	const messages = useSelector(getAlertNotifications);

	// useEffect(() => {
	// 	const connection = createSignalRConnection();

	// 	// Function to start the connection
	// 	const startSignalRConnection = async () => {
	// 		try {
	// 			// Check connection state
	// 			if (connection._connectionState === "Connected") return;

	// 			await connection.start();
	// 			console.log("SignalR Connected");
	// 		} catch (err) {
	// 			console.error("SignalR Connection Error:", err);
	// 			// Retry connection after 30 seconds
	// 			setTimeout(startSignalRConnection, 30000);
	// 		}
	// 	};
	// 	const onReceiveMessage = (callback) => {
	// 		connection.on("ReceiveAlert", callback);
	// 	};
	// 	// Start the connection
	// 	startSignalRConnection();

	// 	// Listen for incoming messages
	// 	onReceiveMessage((user, message) => {
	// 		setMessages((prevMessages) => [...prevMessages, { user, message }]);
	// 	});

	// 	// Listen for incoming messages
	// 	// connection.on("ReceiveMessage", (user, message) => {
	// 	//     setMessages((prevMessages) => [...prevMessages, { user, message }]);
	// 	// });

	// 	// Clean up connection on component unmount
	// 	return () => {
	// 		connection.stop().then(() => console.log("SignalR Disconnected"));
	// 	};
	// }, []);

	return (
		<div style={{ background: "lightgray" }}>
			<Paper>
				<div style={{ height: "7rem" }} className="m-2 mt-0">
					<Typography className="h2">Alerts</Typography>
					<CustomBreadCrumb breadCrumbItems={["alerts"]} />
				</div>
				<div className="d-flex justify-content-between align-items-center m-2">
					<Typography variant="body1">All Alerts</Typography>
					<div
						className="pointer d-flex "
						onClick={() => {}}
						style={{ fontSize: "16px", fontWeight: "700", color: "#345DAE" }}
					>
						<Typography
							variant="body1"
							className="h6 me-2"
							style={{
								fontSize: "16px",
								fontFamily: "Barlow",
								fontWeight: "700",
							}}
						>
							Filters
						</Typography>
						<FilterList style={{ fontSize: "1.5rem" }} />
					</div>
				</div>
			</Paper>
			<Divider style={{ borderColor: "#ABABAB" }} />
			<div id="alerts-content" className="alerts-content" style={{ flex: 1 }}>
				<div>
					{messages
						.map((alert) => serializeStringToJson(alert.user))
						.map((alert, index) => (
							<div className="m-2 max-w-md bg-white rounded-lg overflow-hidden">
								<div
									key={index}
									className="p-4 border-b border-gray-200 last:border-b-0 pointer alert-item"
								>
									<div className="d-flex justify-content-between">
										<div className="flex items-center mb-2">
											<InfoOutlined
												className="w-5 h-5 text-red-500 me-2"
												style={{ fontSize: "12px", color: "red" }}
											/>
											<span className="text-red-500 font-bold alert-sourceName">
												{alert.source}
											</span>
										</div>
										<div className="d-flex">
											<StatusIndicator
												status="rejected"
												width={"12px"}
												height={"12px"}
												style={{
													margin: "0rem 2rem",
													position: "relative",
												}}
											/>
											<Close style={{ fontSize: "16px", marginTop: "3px" }} />
										</div>
									</div>
									<h2 className="text-lg font-semibold mb-2 alert-title">
										{alert.alertTitle}
									</h2>
									{alert.alertMessage && (
										<p className="text-gray-600 mb-2 alert-content">
											{alert.alertMessage}
										</p>
									)}
									<span className="text-sm text-gray-500">
										{calculateTimeAgoText(alert.alertdateTime)}
									</span>
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default AlertNotifications;
