import React, { useContext, useEffect, useState } from "react";
import AddEditProject from "./AddNewProject";
import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	IconButton,
	LinearProgress,
	MenuItem,
	Tooltip,
	Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	addUpdateProject,
	deleteProjectById,
	fetchProjects,
} from "actions/projectActions";
import { getProjects } from "selectors/projectSelector";
import DataGridTable from "components/Table/DataGrid";

import BurgerMenu from "components/Menu/BurgerMenu";
import {
	AssignmentOutlined,
	CloseOutlined,
	Delete,
	EditOutlined,
	VerifiedUser,
} from "@mui/icons-material";
import AddNewJob from "pages/job/AddNewJob";
import get from "lodash/get";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ModelDialog } from "components/Model";
import ManageUsers from "./tabComponents/ManageUsers";
import HiddenOn from "components/Wrappers/HiddenOn";
import AppRoleContext from "context/AppRoleContext";
import {
	hasAppRole,
	isProjectManager,
	roleConfigKeys,
} from "core/utils/roleUtils";
import ProjectTeamList from "./tabComponents/ProjectTeamList";

import { getUserProfileDetails } from "selectors/appSelector";

const columns = (props) => [
	{
		field: "projectName",
		headerName: "Name",
		sortable: false,
		width: 150,
		resizable: false,
		renderCell: (rowData) => {
			return (
				<Tooltip title={rowData.formattedValue}>
					<p
						className="link-text"
						style={{ height: "100%", alignContent: "center" }}
						onClick={() =>
							// props.navigate(`/projects/view/${rowData.row.projectId}`)
							props.navigate(
								`/properties/${props.propertyId}/projects/${rowData.row.projectId}`,
								{
									state: {
										...props.locationState,
										projectName: rowData.formattedValue,
										projectId: rowData.row.projectId,
									},
								}
							)
						}
					>
						{rowData.formattedValue}
					</p>
				</Tooltip>
			);
		},
	},
	{
		field: "projectCode",
		headerName: "Project Number",
		sortable: false,
		width: 150,
		resizable: false,
	},
	{
		field: "property",
		headerName: "Addrress",
		resizable: false,
		width: 200,
		renderCell: (rowData) => {
			return (
				<p
					style={{ height: "100%", alignContent: "center" }}
					// style={{ color: "#0B2257" }}
				>
					{rowData.row.property.address}
				</p>
			);
		},
	},
	{
		filed: "completionPercentage",
		headerName: "Completion %",
		sortable: false,
		resizable: false,
		width: 150,
		align: "center",
		renderCell: (rowData) => {
			return (
				<Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
					<Box sx={{ width: "100%", mr: 1 }}>
						<LinearProgress
							variant="determinate"
							value={get("rowData", "row.statusComplete", 0)}
						/>
					</Box>
					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" sx={{ color: "text.secondary" }}>
							{`${rowData.row.statusComplete}%`}
						</Typography>
					</Box>
				</Box>
			);
		},
	},
	{
		field: "projectStatus",
		headerName: "Stage",
		sortable: false,
		resizable: false,
		width: 150,
		renderCell: (rowData) => {
			return (
				<p style={{ height: "100%", alignContent: "center" }}>
					{get(rowData, "row.projectStatus.statusName", "NA")}
				</p>
			);
		},
	},
	{
		field: "projectType",
		headerName: "Project Type",
		resizable: false,
		width: 150,
	},
	{
		field: "customerName",
		headerName: "Homeowner ",
		resizable: false,
		width: 150,
		renderCell: (rowData) => {
			return (
				<p
					style={{ height: "100%", alignContent: "center" }}
					// style={{ color: "#0B2257" }}
				>
					{rowData.row.property.homeOwnerName}
				</p>
			);
		},
	},

	{
		field: "actions",
		type: "actions",
		flex: 1,
		width: 100,
		align: "right",
		getActions: (params) => {
			const projUserRole = get(params, "row.projUserRole", {});
			return params.row.stage === "Completed"
				? [
						<HiddenOn
							isHidden={
								!hasAppRole(roleConfigKeys.EDIT_PROJECT, props.appRoleDetails)
							}
						>
							<BurgerMenu>
								<MenuItem
									onClick={() => {
										props.deleteProject(params.id);
									}}
								>
									<Delete /> <p className="m-0">Delete</p>
								</MenuItem>
							</BurgerMenu>
						</HiddenOn>,
				  ]
				: [
						<HiddenOn
							isHidden={!isProjectManager(props.appRoleDetails, projUserRole)}
						>
							<BurgerMenu>
								<MenuItem
									onClick={() => {
										props.setEditProject(true);
										props.setAddNewProject(true);
										props.setSelectedProject({
											id: params.id,
											data: params.row,
										});
									}}
								>
									<EditOutlined /> <p className="m-0">Edit</p>
								</MenuItem>
								<MenuItem
									onClick={() => {
										props.setAddJob(true);
										props.setSelectedProject({
											id: params.id,
											data: params.row,
										});
									}}
								>
									<AssignmentOutlined /> <p className="m-0">Add Job</p>
								</MenuItem>
								<MenuItem
									onClick={() => {
										props.setUserManagement(true);
										props.setProjectActionsContext(params.id);
									}}
								>
									<VerifiedUser />
									Manage Team
								</MenuItem>
								<MenuItem
									onClick={() => {
										props.deleteProject(params.id);
									}}
								>
									<Delete /> <p className="m-0">Delete</p>
								</MenuItem>
							</BurgerMenu>
						</HiddenOn>,
				  ];
		},
	},
];

export const Projects = ({
	propertyData = {},
	fromProperty = false,
	hideTitle = false,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { state = {} } = location;
	const { propertyId } = useParams();
	const [addNewProject, setAddNewProject] = useState(false);
	const { projectsList, totalCount } = useSelector(getProjects);
	// const propertyRawData = useSelector(getPropertyData);
	// const propertyData = omit(propertyRawData, ["projectHeaderList"]);

	const [pageModel, setPageModel] = React.useState({
		page: 0,
		pageSize: 10,
	});
	const [selectedRows, setSelectedRows] = React.useState([]);
	const [editProject, setEditProject] = useState(false);
	const [addJob, setAddJob] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [userManagement, setUserManagement] = useState(false);
	const [projectActionsContext, setProjectActionsContext] = useState(null);

	const appRoleDetails = useContext(AppRoleContext);
	const userProfileDetails = useSelector(getUserProfileDetails);

	useEffect(() => {
		dispatch(
			fetchProjects({
				// ...pageModel,
				pageSize: pageModel.pageSize,
				pageNumber: pageModel.page + 1,
				propertyId,
			})
		);
	}, [dispatch, pageModel, propertyId]);

	if (addJob) {
		return (
			<AddNewJob
				onCancel={() => setAddJob(false)}
				projectId={{ projectId: selectedProject.id }}
			/>
		);
	}

	return (
		<div className="projects-page">
			<ModelDialog
				fullWidth
				open={userManagement}
				handleClose={() => {
					setUserManagement(false);
				}}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography className="h4 mb-4">{"Manage Team"}</Typography>
						<IconButton
							onClick={() => {
								setUserManagement(false);
							}}
						>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					<>
						<ManageUsers projectRefId={projectActionsContext} />
						<ProjectTeamList
							projectRefId={projectActionsContext}
							title={"Assigned Users"}
						/>
					</>
				}
			/>
			{addNewProject ? (
				<AddEditProject
					onCancel={() => {
						setAddNewProject(false);
						setEditProject(null);
						setSelectedProject(null);
					}}
					jobId={editProject}
					selectedProject={{
						data: {
							...get(selectedProject, "data", {}),
							propertyId: propertyData.propertyId,
							property: propertyData,
							customerName: propertyData.homeOwnerName,
							homeownerMobile: propertyData.homeownerMobile,
							mobileNumber: userProfileDetails.mobileNumber,
						},
					}}
					propertyId={propertyData.propertyId}
					projectId={selectedProject?.id}
					fromProperty={fromProperty}
					title={get(selectedProject, "data.projectName", null)}
				/>
			) : (
				<>
					<div className="projects-header-section">
						<div>
							<div className="d-flex justify-content-between">
								{!hideTitle && <Typography className="h1">Projects</Typography>}
								<HiddenOn
									isHidden={
										!hasAppRole(roleConfigKeys.CREATE_PROJECT, appRoleDetails)
									}
								>
									<div className="d-flex justify-content-end ">
										<button
											className="primaryButton " // addProjectButton
											style={{ width: "8rem" }}
											onClick={() => {
												setAddNewProject(!addNewProject);
											}}
										>
											{"+ Add Project"}
										</button>
									</div>
								</HiddenOn>
							</div>
							<HiddenOn
								isHidden={totalCount === 0 || projectsList.length === 0}
							>
								<Typography className="total-count-text mt-3 mb-3">
									Displaying{" "}
									{`${pageModel.pageSize * pageModel.page + 1} - ${Math.min(
										pageModel.pageSize * (pageModel.page + 1),
										totalCount
									)}`}{" "}
									of {totalCount}
								</Typography>
								<Divider className="divider" />

								<div data-testid={"properties-select-div "} className="d-flex">
									<FormGroup className="ms-3" aria-disabled>
										<FormControlLabel
											disabled
											disableTypography
											control={<Checkbox />}
											label="Select All"
										/>
									</FormGroup>
									<IconButton disabled={true} onClick={() => {}}>
										<Delete
											style={{
												fontSize: "20px",
												color: false ? "black" : "lightgray",
											}}
										/>
										<Typography variant="body1">
											<strong>Delete</strong>
										</Typography>
									</IconButton>
									<IconButton
										disabled={true}
										// onClick={() =>
										// 	navigate(`/property/edit/${selectedRecord.propertyId}`)
										// }
									>
										<EditOutlined
											style={{
												fontSize: "20px",
												marginTop: "5px",
												color: false ? "black" : "lightgray",
											}}
										/>
										<Typography variant="body1">
											<strong>Edit</strong>
										</Typography>
									</IconButton>
								</div>
							</HiddenOn>
						</div>
					</div>
					<div className="projects-content-section">
						<DataGridTable
							getRowHeight={() => "auto"}
							columns={columns({
								setEditProject,
								setAddJob,
								setAddNewProject,
								setSelectedProject,
								deleteProject: (id) => {
									dispatch(deleteProjectById(id, pageModel));
								},
								navigate: navigate,
								updateStatus: (value, data) => {
									dispatch(
										addUpdateProject({
											formData: {
												...data,
												stage: value,
											},
											params: { ...pageModel, propertyId },
										})
									);
								},
								setUserManagement,
								appRoleDetails,
								setProjectActionsContext,
								propertyId,
								locationState: state,
							})}
							data={projectsList}
							defineRowId={(row) => {
								return row.projectId;
							}}
							pagination={totalCount > 10}
							page
							totalCount={totalCount}
							paginationModel={pageModel}
							onPaginationChange={(props) => {
								setPageModel(props);
							}}
							onSelect={(props) => {
								setSelectedRows(props);
							}}
							selectedRows={selectedRows}
							onDeleteSelection={() => {
								console.log("Delete Selection : ", selectedRows);
							}}
							showToolbar={false}
							onRowClick={(row) => {
								// navigate(`/projects/view/${row.id}`);
							}}
						/>
					</div>
				</>
			)}

			{/* {!addNewProject && (
					<div className="page-footer  fixed-footer align-content-center">
						<div className="d-flex justify-content-end ">
							<button
								className="primaryButton addProjectButton"
								style={{ width: "8rem" }}
								onClick={() => {
									setAddNewProject(!addNewProject);
								}}
							>
								{"+ Add Project"}
							</button>
						</div>
					</div>
				)} */}
		</div>
	);
};

export default Projects;
