import { createSelector } from "reselect";

const selectState = (state) => state.documents;

// Selector to get the list of all files
export const selectAllFiles = createSelector(
	selectState,
	(filesData) => filesData?.files || []
);

// Selector to get the uploading status
export const selectIsUploading = createSelector(
	selectState,
	(filesData) => filesData?.isUploading
);

// Selector to get fetching status
export const selectIsFetching = createSelector(
	[selectState],
	(filesData) => filesData?.isFetching
);

// Selector to get upload error
export const selectUploadError = createSelector(
	selectState,
	(filesData) => filesData?.uploadError
);

// Selector to get fetch error
export const selectFetchError = createSelector(
	selectState,
	(filesData) => filesData?.fetchError
);

// Selector to get files filtered by type (e.g., PDFs only)
export const selectFilesByType = (fileType) =>
	createSelector([selectAllFiles], (files) =>
		files?.filter((file) => file.type === fileType)
	);

// Selector to get the number of files uploaded
export const selectFilesCount = createSelector(
	[selectAllFiles],
	(files) => files?.length
);

export const getPreviewUrl = createSelector(selectState, (documents) => {
	return documents.previewURL || null;
});
