import React, { useState, useEffect } from "react";
import { Drawer, List, ListItemButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink2";

// context
import { useLayoutState } from "../../context/LayoutContext";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { hasChildren } from "../Menu/MenuUtils";
import "./sidebar.scss";
import { getSidebarOptions } from "./sidebarUtils";
import { AccountCircleOutlined, MoreHorizOutlined } from "@mui/icons-material";
import { get } from "lodash";
import { MOBILE_MAX_WIDTH_768 } from "constants/appConstants";

const MenuItem = ({ item }) => {
	const Component = hasChildren(item) ? MultiLevel : SingleLevel;
	return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
	return (
		<ListItemButton button className="sidebar-menu-item">
			<ListItemIcon className="sidebar-menu-icon">{item.icon}</ListItemIcon>
			<ListItemText className="sidebar-menu-text" primary={item.title} />
		</ListItemButton>
	);
};

const MultiLevel = ({ item }) => {
	const { items: children } = item;
	const [open, setOpen] = useState(true);

	const handleClick = () => {
		setOpen((prev) => !prev);
	};

	return (
		<React.Fragment>
			<ListItemButton className="sidebar-menu-item" onClick={handleClick}>
				<ListItemIcon className="sidebar-menu-icon">{item.icon}</ListItemIcon>
				<ListItemText className="sidebar-menu-text" primary={item.title} />
				{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{children.map((child, key) => (
						<MenuItem key={key} item={child} />
					))}
				</List>
			</Collapse>
		</React.Fragment>
	);
};

function Sidebar({ location, userProfile }) {
	var classes = useStyles();
	var theme = useTheme();

	// global
	var { isSidebarOpened } = useLayoutState();

	// local
	var [isPermanent, setPermanent] = useState(true);
	const isMobile = useMediaQuery(MOBILE_MAX_WIDTH_768);

	useEffect(function () {
		window.addEventListener("resize", handleWindowWidthChange);
		handleWindowWidthChange();
		return function cleanup() {
			window.removeEventListener("resize", handleWindowWidthChange);
		};
	});
	const [hoverIndex, setHoverIndex] = useState(null);

	return (
		<Drawer
			variant={isPermanent ? "permanent" : "temporary"}
			className={`${classNames(classes.drawer, {
				[classes.drawerOpen]: isSidebarOpened,
				[classes.drawerClose]: !isSidebarOpened,
			})} sidebar-menu`}
			classes={{
				paper: classNames({
					[classes.drawerOpen]: isSidebarOpened,
					[classes.drawerClose]: !isSidebarOpened,
				}),
			}}
			sx={{
				display: isMobile ? 'none' : 'block',
			}}
			open={isSidebarOpened}
		>
			<div className={classes.toolbar} />
			<div className="d-flex flex-column justify-content-between sidebar-drawer">
				<List className={classes.sidebarList}>
					{getSidebarOptions(
						get(userProfile, "role.applUserCategory", "default"),
						get(userProfile, "role.applRoleName", "default")
					).map((link, index) => (
						<SidebarLink
							key={link.id}
							location={location}
							isSidebarOpened={isSidebarOpened}
							desktopMenu={isPermanent}
							onMenuClick={() => {
								if (link.onClick) {
									link.onClick();
								}
							}}
							index={`p${index}`}
							hoverIndex={hoverIndex}
							setHoverIndex={setHoverIndex}
							{...link}
						/>
					))}
				</List>
				{/* <div className="sidebar-footer">
					<div className="footer-menu d-flex justify-content-between">
						<AccountCircleOutlined />
						<Typography variant="body1" className="profile-name-text">
							{userProfile.firstName}
						</Typography>
						<MoreHorizOutlined />
					</div>
				</div> */}
			</div>
		</Drawer>
	);

	// ##################################################################
	function handleWindowWidthChange() {
		var windowWidth = window.innerWidth;
		var breakpointWidth = theme.breakpoints.values.md;
		var isSmallScreen = windowWidth < breakpointWidth;

		if (isSmallScreen && isPermanent) {
			setPermanent(false);
		} else if (!isSmallScreen && !isPermanent) {
			setPermanent(true);
		}
	}
}

export default Sidebar;
