import React, { createContext, useContext, useState } from "react";

const VoiceAssistantStateContext = createContext();

export const VoiceAssistantProvider = ({ children }) => {
  const [VoiceAssistantState, setVoiceAssistantState] = useState({
    isListening: false,
  });

  return (
    <VoiceAssistantStateContext.Provider
      value={{ VoiceAssistantState, setVoiceAssistantState }}
    >
      {children}
    </VoiceAssistantStateContext.Provider>
  );
};

export const useVoiceAssistantState = () =>
  useContext(VoiceAssistantStateContext);
